import React from 'react';
import { Container, Grid } from '@material-ui/core';
import '../../../assets/styles/settings-module.scss';
import { S3_ASSETS_PATH } from '../../../constants';
import { DefaultAction, ExceptionalCsps } from '../components';

import { Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRetweet } from '@fortawesome/pro-regular-svg-icons';

export default function Settings(props) {
  return (
    <section className="settings-section" data-testid="setting">
      <Container maxWidth={false} className="settings-container">
        <Grid container className="">
          <Grid item xs={12}>
            <div className="title text-center">
              <h3 className="heading2">
                <Box flexDirection="row" justifyContent="center">
                  <Box margin={{ right: 'xs' }}>
                    <FontAwesomeIcon icon={faArrowsRetweet} />
                  </Box>
                  <span>Automation Rules for Campaigns by Downstream CNPs</span>
                </Box>
              </h3>
            </div>
          </Grid>
          <Grid item xs={12}>
            <DefaultAction role={props.role} />
          </Grid>
          <Grid item xs={12}>
            <ExceptionalCsps role={props.role} />
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}
