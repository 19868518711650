import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

const NoAccess = (props) => {
  return (
    <section className="no-access-section">
      <Container maxWidth={false} className="no-access-container">
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ height: window.innerHeight - 115 }}
        >
          <Grid item xs={12} className="text-center">
            <h3 className="heading1">Access Denied</h3>
            <p className="heading3">
              Sorry, but you don't have permission to access this page..!
            </p>
            <Link className="primary-btn" to="/">
              Back to Home
            </Link>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};
export default NoAccess;
