import React, { useState, useEffect } from 'react';
import { Flex, BoxV2 as Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareCheck } from '@fortawesome/pro-regular-svg-icons';
import { UserProfile } from '../../types';
import Field from '../EmailPreferences/Field';

const Enablements: React.FC<{
  profile?: UserProfile;
}> = ({ profile }) => {
  const [loading, setLoading] = useState(!profile);

  useEffect(() => {
    if (profile) {
      setLoading(false);
    }
  }, [profile]);

  return (
    <Box
      sx={{
        mt: 'xxl',
        p: 'xxl',
        border: '1px solid #C0CCD4',
        fontFamily: 'normal',
        color: 't.grey500',
        lineHeight: 'normal',
      }}
    >
      <Flex
        sx={{
          alignItems: 'center',
          fontSize: 'H500',
          fontWeight: 600,
          width: '100%',
          pb: 'm',
          borderBottom: '1px solid',
          borderColor: '#CCCFD0',
        }}
      >
        <FontAwesomeIcon
          icon={faSquareCheck}
          style={{ marginRight: '8px', fontSize: '24px' }}
        />
        <Box sx={{ fontSize: 'H500' }} as="h2">
          Enablements
        </Box>
      </Flex>
      <Box
        sx={{
          mt: 'xs',
          fontSize: 'H300',
          fontWeight: 400,
          display: 'grid',
          gridTemplateColumns: '50% 50%',
          py: 's',
          rowGap: 's',
        }}
      >
        <Field
          label="DPA Signed"
          loading={loading}
          value={profile?.dpaSigned ? 'Yes' : 'No'}
          data-testid="enablementsDpaSigned"
        />
        <Field
          label="Sole Proprietor Enabled"
          loading={loading}
          value={profile?.soleProprietorEnabled ? 'Yes' : 'No'}
          data-testid="enablementsSoleProprietorEnabled"
        />
      </Box>
    </Box>
  );
};

export default Enablements;
