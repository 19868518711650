import React from 'react';
import { Slide, Snackbar } from '@material-ui/core';
import PropTypes from 'prop-types';

import { Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faBell } from '@fortawesome/pro-regular-svg-icons';

const SlideTransition = (props) => <Slide {...props} direction="left" />;

const ElectCNPAlert = ({ open, onClose }) => {
  return (
    <div className="elect-cnp-alert-wrapper" data-testid="electCnpAlert">
      <Snackbar
        TransitionComponent={SlideTransition}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        message={
          <div
            className="alert-content-wrapper"
            data-testid="electCnpAlertMessage"
          >
            <FontAwesomeIcon
              icon={faBell}
              className="alert-icon"
              style={{ color: 'white' }}
            />
            <div className="alert">
              <h3 className="heading1">Reminder!</h3>
              <p className="paragraph">
                Only Sole Proprietor enabled DCAs shown in list
              </p>
            </div>
            <FontAwesomeIcon
              icon={faXmark}
              className="close-icon"
              style={{ color: 'white' }}
              onClick={onClose}
            />
          </div>
        }
        onClose={onClose}
      />
    </div>
  );
};

ElectCNPAlert.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ElectCNPAlert;
