import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { Flex, MultimediaList, AttachmentPreview } from 'portal-commons';
import { type AttachmentInfo } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImage } from '@fortawesome/pro-regular-svg-icons';
import { getCampaignAttachmentsApi, downloadAttachmentApi } from '../apis';
import { ATTACHMENT_FOLDER_MMS } from '../constants';

const CampaignSampleMultimedia: React.FC<{ campaignUid: string }> = ({
  campaignUid,
}) => {
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState(false);
  const [attachments, setAttachments] = useState<AttachmentInfo[]>([]);
  const [selectedAttachment, setSelectedAttachment] =
    useState<AttachmentInfo | null>(null);

  const fetchAttachments = async () => {
    const response = await getCampaignAttachmentsApi(
      campaignUid,
      ATTACHMENT_FOLDER_MMS
    );
    setAttachments(response);
  };

  const loadAttachmentAsFile = async (attach: AttachmentInfo) => {
    const response = await downloadAttachmentApi(attach.uuid);
    if (response) {
      return new File([response], attach.fileName, { type: attach.mimeType });
    }
    return null;
  };

  const updateAttachments = (updatedAttachment: AttachmentInfo) => {
    const updatedAttachments = attachments.map((attach) => {
      if (attach.uuid === updatedAttachment.uuid) {
        return updatedAttachment;
      }
      return attach;
    });
    setAttachments(updatedAttachments);
  };

  const handleSelect = async (index: number) => {
    const selected = attachments[index];
    if (selected && !selected.file) {
      setLoading(true);
      const loadFile = await loadAttachmentAsFile(selected);
      if (loadFile) {
        selected.file = loadFile;
        updateAttachments(selected);
      }
      setLoading(false);
    }
    setPreview(true);
    setSelectedAttachment(selected);
  };

  const handleDownload = async (index: number) => {
    const selected = attachments[index];
    if (!selected) return;

    let loadFile: File | null = null;
    if (selected.file) {
      loadFile = selected.file;
    } else {
      setLoading(true);
      loadFile = await loadAttachmentAsFile(selected);
      if (loadFile) {
        selected.file = loadFile;
        updateAttachments(selected);
      }
      setLoading(false);
    }

    if (loadFile) {
      const url = URL.createObjectURL(loadFile);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', selected.fileName);
      document.body.appendChild(link);
      link.click();
      // Clean up and remove the link
      document.body.removeChild(link);
    }
  };

  useEffect(() => {
    fetchAttachments();

    return () => {
      setAttachments([]);
      setPreview(false);
      setSelectedAttachment(null);
    };
  }, [campaignUid]);

  return (
    <div
      className="sample-messages details-wrapper"
      data-testid="campaignSampleMultimedia"
    >
      <div className="title">
        <h3 className="heading2">
          <Flex sx={{ alignItems: 'center' }}>
            <FontAwesomeIcon icon={faFileImage} style={{ marginRight: 8 }} />
            <span>Sample Multimedia</span>
          </Flex>
        </h3>
      </div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MultimediaList
            data-testid="sampleMultimediaList"
            portal="dca"
            title="Sample Multimedia Files"
            loading={loading}
            attachments={attachments}
            onSelect={handleSelect}
            onDownload={handleDownload}
          />
        </Grid>
      </Grid>
      {selectedAttachment && selectedAttachment.file && (
        <AttachmentPreview
          file={selectedAttachment?.file}
          mimeType={selectedAttachment?.mimeType}
          onClose={() => setPreview(false)}
        />
      )}
    </div>
  );
};

export default CampaignSampleMultimedia;
