import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/pro-regular-svg-icons';

import { Button } from 'portal-commons';

const FilterToggleButton = ({ visible = false, onClick, testId }) => {
  const handleClick = (e) => {
    onClick?.(e);
  };

  return (
    <Button
      variant="standard"
      color="primary"
      onClick={handleClick}
      portal="dca"
      data-testid={testId ?? 'tableAddFilterButton'}
    >
      <FontAwesomeIcon icon={faFilter} size="lg" />
      {visible ? 'Hide' : 'Show'} Filters
    </Button>
  );
};

export default FilterToggleButton;
