import React from 'react';
import { Flex, BoxV2 as Box } from 'portal-commons';
import { UserProfile } from '../../types';

interface Props {
  user?: UserProfile;
}

const UserProfileBasicInfo: React.FC<Props> = ({ user }) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'normal',
        fontSize: 'H400',
        fontWeight: 400,
        lineHeight: 'normal',
        color: 't.grey500',
      }}
    >
      <Box
        as="h1"
        sx={{
          fontSize: 'H600',
          fontWeight: 400,
          mb: 'xxxxl',
        }}
      >
        Display Name:{' '}
        <strong style={{ fontWeight: 700 }}>{user?.displayName}</strong>
      </Box>
      <Flex sx={{ gap: 'xxxxl' }}>
        <Box data-testid="dcaId">
          DCA ID: <strong style={{ fontWeight: 700 }}>{user?.uid}</strong>
        </Box>
      </Flex>
    </Flex>
  );
};

export default UserProfileBasicInfo;
