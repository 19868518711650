import React, { useState } from 'react';
import { ExpansionPanel, ExpansionPanelSummary } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';

const useStyles = makeStyles({
  icon: {
    marginRight: 0,
  },
});

export default function ExpansionList(props) {
  const [expanded, toggleExpansion] = useState(false);
  const classes = useStyles();

  return (
    <ExpansionPanel
      expanded={expanded}
      onChange={() => toggleExpansion(!expanded)}
      {...props}
    >
      <ExpansionPanelSummary
        id="panel1d-header"
        aria-controls="panel1d-content"
        classes={{ expandIcon: classes.icon }}
        expandIcon={<FontAwesomeIcon icon={faAngleDown} size="2xs" />}
      >
        {!expanded ? props.title : props.children}
      </ExpansionPanelSummary>
    </ExpansionPanel>
  );
}
