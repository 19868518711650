import * as actions from '../actions';

export default function reducer(
  state = {
    loadingMessage: '',
  },
  action
) {
  switch (action.type) {
    case actions.SET_LOADING_MESSAGE:
      return {
        ...state,
        loadingMessage: action.payload,
      };
    case actions.CLEAR_LOADING_MESSAGE:
      return {
        ...state,
        loadingMessage: '',
      };
    default:
      return state;
  }
}
