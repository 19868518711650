export const ATTACHMENT_FOLDER_MMS = 'MMS_SAMPLE_MEDIA';
export const ATTACHMENT_FOLDER_CTA = 'SUPPORTING_DOCUMENT';

export const ENTITY_TYPES = [
  {
    label: 'Publicly Traded Company',
    value: 'PUBLIC_PROFIT',
  },
  {
    label: 'Private Company',
    value: 'PRIVATE_PROFIT',
  },
  {
    label: 'Non-Profit Organization',
    value: 'NON_PROFIT',
  },
  {
    label: 'Government',
    value: 'GOVERNMENT',
  },
  {
    label: 'Sole Proprietor',
    value: 'SOLE_PROPRIETOR',
  },
];

export const MIGRATION_STATUS_LABEL_MAP = {
  ACTIVE: 'In Progress',
  COMPLETED: 'Completed',
  CANCELLED: 'No',
};
