import React, { useState, useEffect } from 'react';
import { CustomSwitch, ToolTip } from '../../../shared_elements';
import WarningDialog from './WarningDialog';
import { enableMfa, disableMfa } from '../apiServices';
import { toastFlashMessage } from '../../../utils';
import { Flex } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLockKeyhole } from '@fortawesome/pro-regular-svg-icons';
import { UserProfile } from '../types';

const MfaBlk: React.FC<{ editable: boolean; profile?: UserProfile }> = ({
  editable,
  profile,
}) => {
  const [mfaEnabled, setMfaEnabled] = useState(profile?.mfaEnabled ?? false);
  const [type, setType] = useState<'DISABLE' | 'ENABLE' | ''>('');
  const [openWarning, setOpenWarning] = useState(false);
  const [actionLoader, setActionLoader] = useState(false);

  const handleUpdateMfa = async (status: boolean) => {
    setActionLoader(true);
    const result = status ? await enableMfa() : await disableMfa();
    setActionLoader(false);
    if (result) {
      setMfaEnabled(status);
      setOpenWarning(false);
      toastFlashMessage(
        `MFA ${status ? 'activated' : 'deactivated'} for all portal users`,
        'success'
      );
      setType('');
    }
  };

  useEffect(() => {
    if (profile) {
      setMfaEnabled(profile.mfaEnabled);
    }
  }, [profile]);

  return (
    <Flex
      sx={{ alignItems: 'center', my: 'l', fontSize: 'H400', gap: 'xs' }}
      data-testid="mfaBlk"
    >
      <FontAwesomeIcon icon={faLockKeyhole} style={{ fontSize: '16px' }} />
      <p style={{ fontWeight: 700 }}>
        Enable Multi Factor Authentication (MFA)
      </p>
      <span style={{ marginLeft: '-8px' }}>
        <ToolTip title="Select this option to activate Multi Factor Authentication for all Users. MFA is available through Google Authenticator, Okta Verify or SMS authentication." />
      </span>
      {profile && (
        <CustomSwitch
          val={mfaEnabled}
          disabled={!editable}
          handleChange={() => {
            setOpenWarning(true);
            setType(mfaEnabled === true ? 'DISABLE' : 'ENABLE');
          }}
          dataAttributes={{
            'data-testid': 'mfaBlkCustomSwitch',
          }}
        />
      )}
      {openWarning ? (
        <WarningDialog
          actionLoader={actionLoader}
          type={type}
          handleUpdateMfa={() => handleUpdateMfa(!mfaEnabled)}
          handleClose={() => {
            setOpenWarning(false);
            setType('');
          }}
        />
      ) : null}
    </Flex>
  );
};

export default MfaBlk;
