import React from 'react';

const Loader = (props) => {
  return (
    <div className={`mesh-loader ${props.hocFlag ? 'hoc' : ''}`}>
      <div className="set-one">
        <div className="circle"></div>
        <div className="circle"></div>
      </div>
      <div className="set-two">
        <div className="circle"></div>
        <div className="circle"></div>
      </div>
    </div>
  );
};
export default Loader;
