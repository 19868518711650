import React from 'react';
import { convertTimeWithTimezone } from '../../../utils/time';
import { withRouter } from 'react-router-dom';
import { TableCell, TableRow, useMediaQuery } from '@material-ui/core';

import ExpansionList from '../../../shared_elements/ExpansionList';

const EventsListingRow = (props) => {
  const matches = useMediaQuery('(max-width:1280px)');
  const matches2 = useMediaQuery('(max-width:1500px)');
  const { event } = props;
  return (
    <TableRow data-testid="EventListingRow">
      <TableCell align="left" data-testid="EventListingRowEventType">
        <span>{event.eventType}</span>
      </TableCell>
      <TableCell align="left" data-testid="EventListingRowCategoryType">
        {event.categoryType}
      </TableCell>
      <TableCell
        align="left"
        className={`${event.campaignUid ? 'events-listing-table-link' : ''}`}
        data-testid="EventListingRowCampaignId"
        onClick={() => {
          if (event.campaignUid) {
            props.history.push({
              pathname: `/campaigns/${event.campaignUid}`,
              state: {
                goBackPage: `${props.location.pathname}${
                  props.location.search ? props.location.search : ''
                }`,
              },
            });
          }
        }}
      >
        {event.campaignUid}
      </TableCell>
      <TableCell align="left" data-testid="EventListingRowBrandId">
        {event.brandUid}
      </TableCell>
      <TableCell align="left" data-testid="EventListingRowCreateDate">
        {convertTimeWithTimezone(event.createDate, 'MM/DD/YYYY')}
        <br />
        {convertTimeWithTimezone(event.createDate, 'H:mm z')}
      </TableCell>
      <TableCell
        align="left"
        style={{ width: 500, overflow: 'hidden' }}
        data-testid="EventListingRowDescription"
      >
        {/* { event.description.length > (matches ? 50 : matches2 ? 65: 70) ? <ExpansionList title={matches ? `${event.description.slice(0, 35)}...`: matches2 ? `${event.description.slice(0, 52)}...` : `${event.description.slice(0, 52)}...`}>{`${event.description}`}</ExpansionList> : event.description }  */}
        {event.description?.length > (matches ? 50 : matches2 ? 78 : 82) ? (
          <ExpansionList
            title={
              matches
                ? `${event.description.slice(0, 40)}...`
                : matches2
                ? `${event.description.slice(0, 62)}...`
                : `${event.description.slice(0, 65)}...`
            }
          >{`${event.description}`}</ExpansionList>
        ) : (
          event.description
        )}
      </TableCell>
    </TableRow>
  );
};

export default withRouter(EventsListingRow);
