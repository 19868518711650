import React from 'react';

import {
  Box,
  CampaignAttributes as BaseCampaignAttributes,
} from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquarePollHorizontal } from '@fortawesome/pro-regular-svg-icons';

export default function CampaignAttributes(props) {
  const { campaign } = props;

  return (
    <div className="attributes details-wrapper">
      <div className="title">
        <h3 className="heading2">
          <Box flexDirection="row" alignItems="center">
            <Box margin={{ right: 'xs' }}>
              <FontAwesomeIcon icon={faSquarePollHorizontal} size="lg" />
            </Box>
            <span>Campaign and Content Attributes</span>
          </Box>
        </h3>
      </div>
      <BaseCampaignAttributes.ReadonlyPanel
        data={{
          ...campaign,
          optinKeywords: campaign.optinKeywords?.split(',') || [],
          optoutKeywords: campaign.optoutKeywords?.split(',') || [],
          helpKeywords: campaign.helpKeywords?.split(',') || [],
        }}
      />
    </div>
  );
}
