import React, { useState, useEffect } from 'react';
import { AppLayout } from 'portal-commons';
import { Header, PrimarySidebar, NoAccess } from '../shared_elements';
import { withOktaAuth } from '@okta/okta-react';
import { withRouter } from 'react-router-dom';
import { APP_ROLES } from '../constants';

const Footer = () => {
  return (
    <div className="footer-hoc-block">
      <div className="text-center">
        <p>
          Confidentiality Notice: The information accessed through The Campaign
          Registry DCA Portal is intended for use by authorized individuals
          only. Any unauthorized access or use of this information is
          prohibited. By using The Campaign Registry DCA Portal, you agree to
          keep all accessed information confidential in accordance with the{' '}
          <a
            href="https://www.campaignregistry.com/TCR-T&Cs.pdf"
            rel="noopener noreferrer"
            target="_blank"
          >
            Terms of Use
          </a>
          .
        </p>
      </div>
    </div>
  );
};

function PageLayoutHoc(ComposedComponent, extraInfo) {
  function PageLayoutHoc(props) {
    const pathName = props.location.pathname;
    const [role, setRole] = useState('ROLE_USER');
    useEffect(() => {
      if (
        localStorage.getItem('userInfo') &&
        JSON.parse(localStorage.getItem('userInfo'))
      ) {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        setRole(
          userInfo.groups && userInfo.groups.length
            ? userInfo.groups[0]
            : 'ROLE_USER'
        );
      }
    }, [localStorage.getItem('userInfo')]);
    const authenticated =
      extraInfo?.authenticated == null ? true : !!extraInfo.authenticated;

    const renderMain = () => (
      <main className="flex-1">
        <div className={authenticated ? 'page-layout' : ''}>
          {!authenticated ? (
            <ComposedComponent {...props} />
          ) : APP_ROLES[role].includes(extraInfo.id) ||
            APP_ROLES['GENERAL'].includes(extraInfo.id) ? (
            <ComposedComponent
              {...props}
              access={APP_ROLES[role]}
              role={role}
            />
          ) : (
            <NoAccess />
          )}
        </div>
      </main>
    );

    return authenticated ? (
      <AppLayout
        authenticated={authenticated}
        sideNavigation={
          <aside style={{ height: '100%' }}>
            <PrimarySidebar
              path={pathName}
              authRoute={!authenticated}
              role={role}
            />
          </aside>
        }
        topBar={
          <Header
            title={extraInfo.title}
            note={extraInfo.note}
            path={pathName}
            role={role}
            authenticated={authenticated}
          />
        }
        footer={authenticated && <Footer />}
      >
        <div className="full-width">{renderMain()}</div>
      </AppLayout>
    ) : (
      /* todo : old login page, to be deprecated in TCR-11066 */
      <>
        <aside>
          <PrimarySidebar
            path={pathName}
            authRoute={!authenticated}
            role={role}
          />
        </aside>
        <div className="flex flex-colum">
          <Header
            title={extraInfo ? extraInfo.title : ''}
            note={extraInfo ? extraInfo.note : ''}
            path={pathName}
            role={role}
          />
          {renderMain()}
        </div>
      </>
    );
  }
  return withOktaAuth(withRouter(PageLayoutHoc));
}
export default PageLayoutHoc;
