import React from 'react';
import { S3_ASSETS_PATH } from '../../../../constants';

import { Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImage, faMessageDots } from '@fortawesome/pro-regular-svg-icons';

interface Props {
  campaign: {
    sample1: string;
    [key: string]: string;
  };
}

const CampaignSampleMessages: React.FC<Props> = (props: Props) => {
  const { campaign } = props;
  return (
    <div className="sample-messages details-wrapper">
      <div className="title">
        <h3 className="heading2">
          <Box flexDirection="row" alignItems="center">
            <Box margin={{ right: 'xs' }}>
              <FontAwesomeIcon icon={faMessageDots} />
            </Box>
            <span>Sample Messages</span>
          </Box>
        </h3>
      </div>
      <ul className="list-unstyled">
        {Object.keys(campaign).map((k, index) => {
          if (k.includes('sample')) {
            return (
              campaign[k] && (
                <SampleMessageItem
                  key={index}
                  index={k.slice(-1)}
                  message={campaign[k]}
                />
              )
            );
          }
        })}
      </ul>
    </div>
  );
};

export default CampaignSampleMessages;

interface MessageProps {
  index: string;
  message: string;
}
const SampleMessageItem: React.FC<MessageProps> = (props: MessageProps) => {
  const { index, message } = props;
  return (
    <li className="sample-message-item-wrapper">
      <h3 className="count secondaryText">{index}</h3>
      <p className="paragraph" data-testid="content">
        {message}
      </p>
    </li>
  );
};
