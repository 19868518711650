import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import {
  globalDeleteService,
  globalPostService,
  globalPutService,
} from '../../../utils/globalApiServices';
import { toastFlashMessage } from '../../../utils';

export default function ApiKeyActionDialog(props) {
  const { type, account } = props;
  const [loader, setLoader] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    if (type == 'delete') {
      globalDeleteService(`dca/api/account/${account.id}`).then((response) => {
        setLoader(false);
        if (response.status >= 200 && response.status < 300) {
          toastFlashMessage(`API Key deleted successfully`, 'success');
          props.handleClose();
          props.fetchApiAccounts();
        }
      });
    } else if (type == 'rotate') {
      globalPutService(`dca/api/account/${account.id}/resetSecret`, {}).then(
        (response) => {
          setLoader(false);
          if (response.status >= 200 && response.status < 300) {
            toastFlashMessage(`API Key rotated successfully`, 'success');
            props.handleClose();
            props.fetchApiAccounts();
          }
        }
      );
    } else if (type == 'clone') {
      globalPostService(`dca/api/account/${account.id}/clone`, {}).then(
        (response) => {
          setLoader(false);
          if (response.status >= 200 && response.status < 300) {
            toastFlashMessage(`API Key cloned successfully`, 'success');
            props.handleClose();
            props.fetchApiAccounts();
          }
        }
      );
    }
  };
  useEffect(() => {
    setLoader(false);
  }, []);
  return (
    <Dialog
      open={true}
      aria-labelledby="responsive-dialog-title"
      maxWidth="xs"
      fullWidth={true}
      id="successModal"
      className="api-action-dialog default-dialog"
      data-testid="apiKeyActionDialog"
    >
      <DialogContent className="content-wrapper">
        <div className="content text-center">
          <p className="paragraph">
            Are you sure you want to <br />
            <span>{`${type} this API Key?`}</span>
            <br />
            <br />
            This action is irrevocable.
          </p>
        </div>
        <form onSubmit={handleSubmit}>
          <ul className="list-inline footer">
            <li>
              <a
                className={`secondary-btn ${loader ? '' : ''}`}
                onClick={() => props.handleClose(false)}
                data-testid="apiKeyActionDialogCancelButton"
              >
                Cancel
              </a>
            </li>
            <li className="form-group-field">
              <input
                data-testid="apiKeyActionDialogSubmitButton"
                type="submit"
                disabled={loader ? true : false}
                value="Proceed"
              />
            </li>
          </ul>
        </form>
      </DialogContent>
    </Dialog>
  );
}
