import CampaignsByCsps from './containers/CampaignsByCsps';
import CampaignsByPrimaryDcas from './containers/CampaignsByPrimaryDcas';
import CampaignDetail from './containers/CampaignDetail';
import { ErrorBoundaryHoc, PageLayoutHoc, RequiredAuth } from '../../hocs';

export const campaignsRoutes = [
  {
    path: '/csp-campaigns',
    component: RequiredAuth(
      PageLayoutHoc(ErrorBoundaryHoc(CampaignsByCsps), {
        title: 'CAMPAIGNS BY CSPs',
        id: 'VIEW_CAMPAIGNS_BY_CSPS',
      })
    ),
    key: 'csp-campaigns',
  },
  {
    path: '/dca-campaigns',
    component: RequiredAuth(
      PageLayoutHoc(ErrorBoundaryHoc(CampaignsByPrimaryDcas), {
        title: 'CAMPAIGNS BY PRIMARY DCAs',
        note: '* Results capped at first 10,000 records',
        id: 'VIEW_CAMPAIGNS_BY_PRIMARY_DCAS',
      })
    ),
    key: 'csp-campaigns',
  },
  {
    path: '/campaigns/:id',
    component: RequiredAuth(
      PageLayoutHoc(ErrorBoundaryHoc(CampaignDetail), {
        title: 'CAMPAIGNS',
        id: 'VIEW_CAMPAIGN_DETAILS',
      })
    ),
    key: 'campaign-detail',
  },
  {
    path: '/csp-campaigns/:id',
    component: RequiredAuth(
      PageLayoutHoc(ErrorBoundaryHoc(CampaignDetail), {
        title: 'CAMPAIGNS',
        id: 'VIEW_CAMPAIGN_DETAILS',
      })
    ),
    key: 'csp-campaign-detail',
  },
  {
    path: '/dca-campaigns/:id',
    component: RequiredAuth(
      PageLayoutHoc(ErrorBoundaryHoc(CampaignDetail), {
        title: 'CAMPAIGNS',
        id: 'VIEW_CAMPAIGN_DETAILS',
      })
    ),
    key: 'dca-campaign-detail',
  },
];
