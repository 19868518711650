import moment from 'moment-timezone';

export const getTimezone = () => {
  let timezone = 'America/Los_Angeles';
  if (Intl && Intl.DateTimeFormat) {
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  return timezone;
};
export const convertTimeWithTimezone = (
  date,
  format = 'MM/DD/YYYY - HH:mm z',
  timezone = getTimezone()
) => {
  if (date) {
    const formattedTime = moment.tz(date, timezone).format(format);
    return formattedTime;
  }
  return '';
};
