import { ErrorBoundaryHoc, PageLayoutHoc, RequiredAuth } from '../../hocs';
import Integrations from './containers/Integrations';

export const integrationRoutes = [
  {
    path: '/integrations',
    component: RequiredAuth(
      PageLayoutHoc(ErrorBoundaryHoc(Integrations), {
        title: 'INTEGRATIONS',
        id: 'API_KEY_INTEGRATION',
      })
    ),
    key: 'integrations',
  },
];
