import { makeStyles } from '@material-ui/core/styles';

export const useStylesTable = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    // marginBottom: theme.spacing(2),
    boxShadow: 'none',
  },
  table: {
    minWidth: 'unset',
  },
  tableWrapper: {
    // overflowX: 'hidden',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    // overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  imageIcon: {
    height: '15px',
    width: '15px',
  },
  checkedImageIcon: {
    width: '17px',
  },
  iconRoot: {
    textAlign: 'center',
    width: 18,
    height: 18,
  },
}));
