import {
  faMinus,
  faCheck,
  faHourglass,
  faEllipsis,
  faXmark,
  faSquareCheck,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CAMPAIGN_STATUS_ICON_CONFIG = {
  pending: {
    icon: faHourglass,
    style: { color: '68737A' },
  },
  accepted: {
    icon: faCheck,
    style: { color: '68737A' },
  },
  declined: {
    icon: faMinus,
    style: { color: '68737A' },
  },
};

const MNO_STATUS_ICON_CONFIG = {
  review: {
    icon: faEllipsis,
  },
  approved: {
    icon: faCheck,
  },
  rejected: {
    icon: faXmark,
  },
  suspended: {
    icon: faMinus,
  },
  registered: {
    icon: faCheck,
  },
};

const getCampaignStatusIcon = (status, additionalProps) => {
  const config = status && CAMPAIGN_STATUS_ICON_CONFIG[status.toLowerCase()];
  if (config) {
    const props = {
      ...config,
      ...additionalProps,
    };
    return <FontAwesomeIcon {...props} />;
  }
  return undefined;
};

const getMnoStatusIcon = (status, additionalProps) => {
  const config = MNO_STATUS_ICON_CONFIG[status.toLowerCase()];
  if (config) {
    const props = {
      ...config,
      ...additionalProps,
    };
    return <FontAwesomeIcon {...props} />;
  }
  return undefined;
};

export { getCampaignStatusIcon, getMnoStatusIcon };
