import React, { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { isEqual } from 'lodash-es';
import { BoxV2 as Box } from 'portal-commons';
import { fetchProfile, updateProfile } from '../apiServices';
import { toastFlashMessage } from '../../../utils';
import { Roles } from '../../../constants/enums';
import {
  UserProfileBasicInfo,
  EmailPreferences,
  Enablements,
  UserManagement,
} from '../components';
import { UserProfile } from '../types';

const UsersProfile: React.FC = () => {
  const { oktaAuth } = useOktaAuth();
  const [userRole, setUserRole] = useState(Roles.Reviewer);
  const [userProfile, setUserProfile] = useState<UserProfile>();
  const [escalateEmails, setEscalateEmails] = useState<string[]>([]);
  const [loadingEmail, setLoadingEmail] = useState(true);

  const fetchProfileAndEmails = async () => {
    const profile = (await fetchProfile()) as UserProfile;
    if (profile) {
      setUserProfile(profile);
      const emails = profile.escalateEmail.split(',');
      setEscalateEmails(emails.filter((mail: string) => mail !== ''));
    }
    setLoadingEmail(false);
  };

  const updateEscalateEmails = async (emails: string[]) => {
    if (isEqual(emails, escalateEmails)) return;

    await updateProfile({ escalateEmail: emails.join(',') });
    toastFlashMessage('Email preferences updated successfully', 'success');
    setEscalateEmails(emails);
  };

  useEffect(() => {
    if (localStorage.getItem('userInfo')) {
      const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
      setUserRole(userInfo?.groups[0] || Roles.Reviewer);
    } else {
      oktaAuth.getUser().then((user) => {
        const groups = user.groups || [Roles.Reviewer];
        setUserRole(groups[0]);
      });
    }

    fetchProfileAndEmails();
  }, []);

  return (
    <Box pt="xxl" data-testid="users">
      <UserProfileBasicInfo user={userProfile} />

      <EmailPreferences
        editable={userRole === Roles.Manager}
        loading={loadingEmail}
        emails={escalateEmails}
        onChange={updateEscalateEmails}
      />

      <Enablements profile={userProfile} />

      <UserManagement
        editable={userRole === Roles.Manager}
        profile={userProfile}
      />
    </Box>
  );
};

export default UsersProfile;
