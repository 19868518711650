import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';

export default function WarningDialog(props) {
  const { type } = props;
  return (
    <Dialog
      open={true}
      onClose={props.handleClose}
      maxWidth="xs"
      fullWidth={true}
      className={`action-warning-dialog default-dialog`}
      data-testid="warningDialog"
      disableBackdropClick
    >
      <DialogContent>
        <div className="content-wrapper">
          <p className="paragraph text-center">
            Are you sure you want to{' '}
            <span>{type == 'ACCEPT' ? 'accept ' : 'decline '}</span>
            <br /> <span>all campaigns</span>by this CSP?
          </p>
        </div>
        <ul className="list-inline footer text-center">
          <li>
            <a
              className="secondary-btn"
              onClick={props.handleClose}
              data-testid="warningDialogCancelButton"
            >
              Cancel
            </a>
          </li>
          <li>
            <a
              className={`primary-btn`}
              onClick={props.handleSubmit}
              data-testid="warningDialogProceedButton"
            >
              Proceed
            </a>
          </li>
        </ul>
      </DialogContent>
    </Dialog>
  );
}
