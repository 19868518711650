import React, { Fragment } from 'react';
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { S3_ASSETS_PATH } from '../../../constants';
import PropTypes from 'prop-types';

import { Box as BoxCommons } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/pro-regular-svg-icons';

const useStyles = makeStyles({
  items: {
    display: 'inline-flex',
    columnGap: '10px',
    rowGap: '20px',
  },
  item: {
    width: '191px',
    padding: '0',
    margin: '0',
  },
  role: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '20px',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '14px',
    color: '#FFFFFF',
    background: '#00698F',
  },
  name: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '20px',
    padding: '0 16px',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '14px',
    color: '#FFFFFF',
    background: 'rgba(0, 105, 143, 0.5)',
    boxSizing: 'border-box',
    '& > span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  arrow: {
    width: '6px',
    height: '6px',
    borderTop: '1px solid #FE772D',
    borderRight: '1px solid #FE772D',
    transform: 'rotate(45deg)',
  },
});

const SharingChain = ({ nodes }) => {
  const classes = useStyles();
  const firstDcaIndex = nodes.findIndex((node) => node.role === 'DCA');

  return (
    <Box className="details-wrapper" data-testid="sharingChain">
      <Grid className="title" container direction="row" alignItems="center">
        <h3 className="heading2">
          <BoxCommons flexDirection="row" alignItems="center">
            <BoxCommons margin={{ right: 'xs' }}>
              <FontAwesomeIcon icon={faLink} />
            </BoxCommons>
            <span>Connectivity Chain</span>
          </BoxCommons>
        </h3>
      </Grid>
      <Grid
        classes={{ root: classes.items }}
        container
        direction="row"
        alignItems="center"
        data-testid="sharingChainDcas"
      >
        {nodes.map((node, index) => (
          <Fragment key={node.uid}>
            {index > 0 && <Box className={classes.arrow} />}
            <Container
              classes={{ root: classes.item }}
              data-testid="sharingChainDcaName"
            >
              <Box className={classes.role}>
                {index > 0
                  ? node.role === 'CSP'
                    ? `CNP ${index}`
                    : node.role === 'DCA'
                    ? `${index === firstDcaIndex ? 'Primary' : 'Secondary'} DCA`
                    : node.role
                  : node.role}
              </Box>
              <Box className={classes.name} title={node.displayName}>
                <span>{node.displayName}</span>
              </Box>
            </Container>
          </Fragment>
        ))}
      </Grid>
    </Box>
  );
};

SharingChain.propTypes = {
  nodes: PropTypes.arrayOf(
    PropTypes.shape({
      role: PropTypes.string.isRequired,
      uid: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      acceptOrElectDate: PropTypes.number,
      myDca: PropTypes.bool,
    })
  ).isRequired,
};

export default SharingChain;
