import { TopBar } from 'portal-commons';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

import { Roles } from '../constants/enums';

const hasProfileAccess = (role) => [Roles.Manager, Roles.User].includes(role);

const Header = ({ role, title, note, authenticated }) => {
  const history = useHistory();
  const { oktaAuth } = useOktaAuth();

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.setItem('logoutSuccess', true);
    oktaAuth.signOut('/login');
  };

  const menus = useMemo(() => {
    return [
      hasProfileAccess(role) ? (
        <TopBar.MenuItem onClick={() => history.push('/profile')}>
          View DCA Profile
        </TopBar.MenuItem>
      ) : null,
      <TopBar.MenuItem onClick={handleLogout}>Logout</TopBar.MenuItem>,
    ].filter(Boolean);
  }, [role]);

  const userName = useMemo(() => {
    return localStorage.getItem('okta-token-storage')
      ? JSON.parse(localStorage.getItem('okta-token-storage')).idToken
        ? JSON.parse(localStorage.getItem('okta-token-storage')).idToken.claims
            .name
        : localStorage.getItem('userInfo')
        ? JSON.parse(localStorage.getItem('userInfo')).name
        : ''
      : '';
  }, []);

  return (
    <TopBar
      authenticated={authenticated}
      portal="dca"
      username={userName}
      title={title}
      subText={note}
      menus={menus}
      role={role}
    />
  );
};
export default Header;
