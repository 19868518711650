import React, { useState, useEffect, useMemo } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { globalGetService } from '../utils/globalApiServices';
import datadog from '../utils/datadog';
import { SideNavigation } from 'portal-commons';
import {
  faHome,
  faBullhorn,
  faCalendarDay,
  faGearCode,
  faGear,
} from '@fortawesome/pro-regular-svg-icons';

function PrimarySidebar(props) {
  const { authRoute, location } = props;
  const [lastLogin, setLastLogin] = useState('');
  useEffect(() => {
    if (localStorage.getItem('user') && !lastLogin) {
      const lastLoginTime = JSON.parse(localStorage.getItem('user')).lastLogin;
      setLastLogin(lastLoginTime);
    }
    if (!authRoute && !localStorage.getItem('user') && !lastLogin) {
      globalGetService('user').then((response) => {
        const user = response.data;
        !datadog.hasUser() && datadog.setUser(user);
        localStorage.setItem('user', JSON.stringify(user));
        setLastLogin(response.data ? response.data.lastLogin : '');
      });
    }
  }, []);

  const navLink = useMemo(() => {
    const link = [
      {
        title: 'Dashboard',
        icon: faHome,
        pathname: '/dashboard',
      },
      {
        title: 'Campaigns by CSPs',
        icon: faBullhorn,
        pathname: '/csp-campaigns',
      },
      {
        title: 'Campaigns by Primary DCAs',
        icon: faBullhorn,
        pathname: '/dca-campaigns',
      },
      {
        title: 'Settings',
        icon: faGear,
        pathname: '/settings',
      },
      {
        title: 'Events',
        icon: faCalendarDay,
        pathname: '/events',
      },
    ];

    if (props.role !== 'ROLE_REVIEWER') {
      link.push({
        title: 'Integrations',
        icon: faGearCode,
        pathname: '/integrations',
      });
    }

    return link;
  }, [props.role]);

  return (
    <div className={`primary-side-menu ${authRoute ? 'auth-page' : ''}`}>
      {authRoute ? null : (
        <>
          <SideNavigation
            portal="dca"
            items={navLink}
            location={location}
            lastLoginTime={lastLogin}
            linkComponent={Link}
          />
        </>
      )}
    </div>
  );
}

export default withRouter(PrimarySidebar);
