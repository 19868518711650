import Login from './containers/Login';
import ResetPassword from './containers/ResetPassword';
import UserAuthThanks from './containers/UserAuthThanks';
import { LoggedUserRedirect, PageLayoutHoc } from '../../hocs';
import ForgotPassword from './containers/ForgotPassword';

export const authRoutes = [
  {
    path: '/login',
    component: LoggedUserRedirect(
      PageLayoutHoc(Login, {
        title: 'WELCOME TO THE CAMPAIGN REGISTRY',
        authenticated: false,
      })
    ),
    key: 'login',
  },
  {
    path: '/',
    component: LoggedUserRedirect(
      PageLayoutHoc(Login, {
        title: 'WELCOME TO THE CAMPAIGN REGISTRY',
        authenticated: false,
      })
    ),
    key: 'login',
  },
  {
    path: '/forgot-password',
    component: LoggedUserRedirect(
      PageLayoutHoc(ForgotPassword, {
        title: 'WELCOME TO THE CAMPAIGN REGISTRY',
        authenticated: false,
      })
    ),
    key: 'forgotPassword',
  },
  {
    path: '/expired-password',
    component: LoggedUserRedirect(
      PageLayoutHoc(ForgotPassword, {
        title: 'WELCOME TO THE CAMPAIGN REGISTRY',
        authenticated: false,
      })
    ),
    key: 'expiredPassword',
  },
  {
    path: '/reset-password',
    component: LoggedUserRedirect(
      PageLayoutHoc(ResetPassword, {
        title: 'WELCOME TO THE CAMPAIGN REGISTRY',
        authenticated: false,
      })
    ),
    key: 'resetPassword',
  },
  {
    path: '/set-password',
    component: LoggedUserRedirect(
      PageLayoutHoc(ResetPassword, {
        title: 'WELCOME TO THE CAMPAIGN REGISTRY',
        authenticated: false,
      })
    ),
    key: 'resetPassword',
  },
  {
    path: '/user/reset/successfully',
    component: LoggedUserRedirect(
      PageLayoutHoc(UserAuthThanks, {
        title: 'WELCOME TO THE CAMPAIGN REGISTRY',
        authenticated: false,
      })
    ),
    key: 'userAuthThanks',
  },
];
