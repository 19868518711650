import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { globalGetService } from '../../../utils/globalApiServices';
import { S3_ASSETS_PATH } from '../../../constants';
import NumericLabel from 'react-pretty-numbers';

import { Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiagramVenn, faBullhorn } from '@fortawesome/pro-regular-svg-icons';

var option = {
  justification: 'C',
  locales: 'en-AU',
  percentage: false,
  wholenumber: null,
  commafy: true,
  shortFormat: true,
  shortFormatMinValue: 10000,
  shortFormatPrecision: 2,
  title: true,
  cssClass: ['red'],
};

export default function DashboardCount(props) {
  const { type, count } = props;
  const [pendingCount, setPendingCount] = useState('');

  useEffect(() => {
    if (type == 'primaryDca') {
      globalGetService(`dca/campaigns/primaryDca`, {
        sharingStatus: 'PENDING',
        status: 'ACTIVE',
      }).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setPendingCount(response.data.totalRecords);
        }
      });
    }
  }, [type]);
  return (
    <div
      className={`campaigns-count-block ${
        type == 'primaryDca' ? 'primaryDca' : ''
      }`}
      data-testid="dashboardCount"
    >
      {type == 'primaryDca' ? (
        <>
          <h3 className="paragraph">
            <Box flexDirection="row">
              <Box margin={{ right: 's' }}>
                <FontAwesomeIcon
                  icon={faBullhorn}
                  style={{ color: '00AC9D', fontSize: '28px' }}
                />
              </Box>
              <span>
                Campaigns
                <br /> Pending Review
              </span>
            </Box>
          </h3>
          <p className="text-center count">
            {pendingCount || pendingCount === 0 ? (
              <NumericLabel params={option}>{pendingCount}</NumericLabel>
            ) : (
              '--'
            )}
          </p>
          <p className="text-center link">
            <Link
              data-testid="dashboardCountReviewButton"
              to={{
                pathname: `/csp-campaigns`,
                search: `?sharingStatus=PENDING&status=ACTIVE`,
              }}
              className="primary-btn"
            >
              Review
            </Link>
          </p>
        </>
      ) : (
        <>
          <h3 className="paragraph">
            <Box flexDirection="row">
              <Box margin={{ right: 's' }}>
                <FontAwesomeIcon
                  icon={faDiagramVenn}
                  style={{ color: 'FE772D', fontSize: '28px' }}
                />
              </Box>
              <span>
                Campaigns by
                <br /> Primary DCAs
              </span>
            </Box>
          </h3>
          <p className="text-center count">
            {count || count === 0 ? (
              <NumericLabel params={option}>{count}</NumericLabel>
            ) : (
              '--'
            )}
          </p>
          <p className="text-center link">
            <Link
              data-testid="dashboardCountViewAllButton"
              to={{
                pathname: '/dca-campaigns',
                search: '?status=ACTIVE',
              }}
              className="primary-btn"
            >
              View All
            </Link>
          </p>
        </>
      )}
    </div>
  );
}
