import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import PropTypes from 'prop-types';

const DeleteConfirmationDialog = ({ open, onCancel, onSubmit }) => {
  return (
    <Dialog
      className={`action-warning-dialog default-dialog`}
      open={open}
      fullWidth
      disableBackdropClick
      maxWidth="xs"
      onClose={onCancel}
      data-testid="deleteConfirmationDialog"
    >
      <DialogContent>
        <div className="content-wrapper">
          <p className="paragraph text-center">
            Are you sure you want to <span>delete</span>
            <br /> <span>this setting</span>?
          </p>
        </div>
        <ul className="list-inline footer text-center">
          <li>
            <a
              className="secondary-btn"
              onClick={onCancel}
              data-testid="deleteConfirmationDialogCancelButton"
            >
              Cancel
            </a>
          </li>
          <li>
            <a
              className={`primary-btn`}
              onClick={onSubmit}
              data-testid="deleteConfirmationDialogProceedButton"
            >
              Proceed
            </a>
          </li>
        </ul>
      </DialogContent>
    </Dialog>
  );
};

DeleteConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default DeleteConfirmationDialog;
