import React, { useState, useEffect } from 'react';
import { Grid, TableCell, TableRow } from '@material-ui/core';
import { AutomatedActionBtn, WarningDialog } from './index';
import { S3_ASSETS_PATH } from '../../../constants';
import { convertTimeWithTimezone } from '../../../utils/time';
import { globalPutService } from '../../../utils/globalApiServices';
import { toastFlashMessage } from '../../../utils';
import { Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';

export default function ExceptionalCspsListingRow(props) {
  const { cspObj, role } = props;
  const [action, setAction] = useState('');
  const [enableSaveBtn, setEnableSaveBtn] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [actionLoader, setActionLoader] = useState(false);
  const [csp, setCsp] = useState(cspObj);
  const isReviewer = role === 'ROLE_REVIEWER';

  const updateAction = () => {
    setOpenWarning(false);
    const formData = {
      action,
      cspUid: csp.cspUid,
      // enabled:
    };
    updateAutomatedAction(formData);
  };
  const updateAutomatedAction = (data = {}) => {
    setActionLoader(true);
    globalPutService(
      `dca/primaryDcaCspRule?action=${data.action}&cspUid=${data.cspUid}`,
      {}
    ).then((response) => {
      setActionLoader(false);
      if (response.status >= 200 && response.status < 300) {
        toastFlashMessage('Automated action updated successfully', 'success');
        setCsp(response.data);
        setAction(response.data.action);
        setEnableSaveBtn(false);
      }
    });
  };

  const handleChangeAction = (option) => {
    if (csp.action !== option) {
      setAction(option);
      setEnableSaveBtn(true);
    } else {
      setAction(option);
      setEnableSaveBtn(false);
    }
  };
  useEffect(() => {
    setCsp(cspObj);
    setAction(cspObj.action);
    setEnableSaveBtn(false);
  }, [cspObj]);
  return (
    <>
      <TableRow data-testid="exceptionalCspsListingRow">
        <TableCell data-testid="exceptionalCspsListingRowCspNameOrCspUid">
          {csp.cspName || csp.cspUid}
        </TableCell>
        <TableCell style={{ padding: 8 }}>
          <AutomatedActionBtn
            action={action}
            exception={true}
            handleChange={handleChangeAction}
            role={role}
          />
        </TableCell>
        <TableCell>
          <a
            className={`primary-btn ${
              actionLoader || !enableSaveBtn || role === 'ROLE_REVIEWER'
                ? 'disabled'
                : ''
            }`}
            onClick={() => {
              if (action !== 'REVIEW') {
                setOpenWarning(true);
              } else {
                updateAction();
              }
            }}
            data-testid="exceptionalCspsListingRowSaveButton"
          >
            Save
          </a>
        </TableCell>
        <TableCell style={{ paddingRight: 40 }}>
          <Box
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box margin={{ right: 's' }}>
              <span>{convertTimeWithTimezone(csp.updateDate)}</span>
            </Box>
            <FontAwesomeIcon
              data-testid="exceptionalCspsListingRowDeleteButton"
              onClick={() => !isReviewer && props.deleteCsp(csp.cspUid)}
              icon={faTrash}
              size="lg"
              style={
                isReviewer
                  ? { color: '#68737a80', cursor: 'not-allowed' }
                  : { cursor: 'pointer' }
              }
            />
          </Box>
        </TableCell>
      </TableRow>
      {openWarning ? (
        <WarningDialog
          handleClose={() => setOpenWarning(false)}
          handleSubmit={updateAction}
          type={action}
        />
      ) : null}
    </>
  );
}
