import React, { useEffect } from 'react';
import { Dialog } from '@material-ui/core';
import { Flex, BoxV2 as Box, Button, TextInput } from 'portal-commons';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faMessageDots,
  faTrashCan,
} from '@fortawesome/pro-regular-svg-icons';
import { MAX_EMAIL_LENGTH, MAX_NOTIFICATION_EMAILS } from '../../constants';
import {
  createMaxLengthValidator,
  isValidEmail,
} from '../../../../utils/validator';

interface Props {
  open: boolean;
  emails: string[];
  onClose: () => void;
  onSave: (emails: string[]) => void;
}
const EditEmailModal: React.FC<Props> = ({ open, emails, onClose, onSave }) => {
  const { control, reset, trigger, getValues } = useForm({
    defaultValues: {
      emails: emails.map((email) => ({ email })),
    },
    mode: 'onBlur',
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'emails',
  });

  useEffect(() => {
    if (open) {
      reset({ emails: emails.map((email) => ({ email })) });
    }
  }, [open, emails]);

  const handleAddEmail = () => {
    append({ email: '' });
  };
  const handleDeleteEmail = (idx: number) => {
    remove(idx);
  };

  const handleSave = async () => {
    const isValid = await trigger();
    if (!isValid) return;

    const data = getValues().emails.map((obj) => obj.email);
    onSave(data);
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth={true}
      aria-labelledby="edit-email-preferences"
      data-testid="editEmailPreferencesDialog"
    >
      <Box p="xxl">
        <Flex
          sx={{
            mb: 'xxl',
            alignItems: 'center',
            fontSize: 'H500',
            fontWeight: 500,
          }}
        >
          <FontAwesomeIcon
            icon={faEnvelope}
            style={{ marginRight: '8px', fontSize: '24px' }}
          />
          <Box sx={{ fontSize: 'H500' }}>Email & Notification Preferences</Box>
        </Flex>
        <Flex
          sx={{
            pb: 'xs',
            width: '100%',
            fontSize: 'H200',
            alignItems: 'center',
            justifyContent: 'flex-start',
            borderBottom: '1px solid',
            borderColor: '#CCCFD0',
          }}
        >
          <FontAwesomeIcon
            icon={faMessageDots}
            style={{ marginRight: '8px', fontSize: '18px' }}
          />
          System Notification Email
          {fields.length < MAX_NOTIFICATION_EMAILS && (
            <Box
              sx={{
                ml: 'm',
                color: 't.blue100',
                cursor: 'pointer',
              }}
              data-testid="addEmailButton"
              onClick={handleAddEmail}
              role="button"
            >
              + Add a New Email
            </Box>
          )}
        </Flex>
        <Box
          sx={{
            pt: 'm',
            gap: 'm',
            fontSize: 'H400',
            alignItems: 'center',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
          }}
          data-testid="editEmailInputContainer"
        >
          {fields.map((field, idx) => (
            <Flex sx={{ alignItems: 'center', width: '100%' }} key={field.id}>
              <Controller
                name={`emails.${idx}`}
                control={control}
                rules={{
                  validate: {
                    isValidLength: (value) =>
                      createMaxLengthValidator(MAX_EMAIL_LENGTH)(value.email),
                    isValidEmail: (value) => isValidEmail(value.email),
                  },
                }}
                render={({
                  field: { value, onChange, onBlur },
                  fieldState: { error = {} },
                }) => (
                  <TextInput
                    label="Email Address"
                    placeholder="Email Address"
                    data-testid="editEmailInput"
                    error={!!error.message}
                    note={error.message}
                    value={value.email}
                    style={{ flex: 1 }}
                    onChange={(value) => onChange({ email: value })}
                    onBlur={onBlur}
                  />
                )}
              />
              {fields.length > 1 && (
                <Box
                  role="button"
                  sx={{
                    cursor: 'pointer',
                    p: 'xs',
                    alignSelf: 'flex-start',
                    mt: 'xxs',
                  }}
                  onClick={() => handleDeleteEmail(idx)}
                  data-testid="deleteEmailButton"
                >
                  <FontAwesomeIcon
                    icon={faTrashCan}
                    style={{ fontSize: '14px' }}
                  />
                </Box>
              )}
            </Flex>
          ))}
        </Box>
        <Flex
          sx={{
            justifyContent: 'center',
            alignItem: 'center',
            gap: 's',
            mt: 'xxl',
            mb: 'l',
          }}
        >
          <Button
            variant="outline"
            onClick={onClose}
            data-testid="cancelButton"
          >
            Cancel
          </Button>
          <Button onClick={handleSave} data-testid="saveButton">
            Save
          </Button>
        </Flex>
      </Box>
    </Dialog>
  );
};

export default EditEmailModal;
