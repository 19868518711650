import {
  globalGetService,
  globalPutService,
  globalDeleteService,
} from '../../utils/globalApiServices';

export const fetchProfile = async () => {
  const response = await globalGetService('dca/dcas/me');
  if (response && response.ok) {
    return response.data;
  }
};

export const updateProfile = async (profile) => {
  const response = await globalPutService('dca/dcas/me', profile);
  if (response && response.ok) {
    return response.data;
  }
};

export const fetchUsers = async () => {
  const response = await globalGetService('dca/manage/users');
  if (response && response.ok) {
    return response.data;
  }
  return [];
};

export const fetchUserRoles = async () => {
  const response = await globalGetService(`dca/manage/users/roles`);
  if (response && response.ok) {
    return response.data;
  }
  return [];
};

export const enableMfa = async () => {
  const response = await globalPutService(`dca/manage/users/mfa`);
  if (response && response.ok) {
    return true;
  }
};

export const disableMfa = async () => {
  const response = await globalDeleteService(`dca/manage/users/mfa`);
  if (response && response.ok) {
    return true;
  }
};

export const createUser = async (data) => {
  const response = await globalPutService(`dca/manage/users`, data);
  if (response && response.ok) {
    return true;
  }
};

export const updateUser = async (userId, data) => {
  const response = await globalPutService(`dca/manage/users/${userId}`, data);
  if (response && response.ok) {
    return true;
  }
};

export const deleteUser = async (userId) => {
  const response = await globalDeleteService(
    `dca/manage/users/${userId}/remove`
  );
  if (response && response.ok) {
    return true;
  }
};
