import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';

export default function WarningDialog(props) {
  const { type, actionLoader } = props;
  return (
    <Dialog
      open={true}
      onClose={props.handleClose}
      maxWidth="xs"
      fullWidth={true}
      className={`action-warning-dialog default-dialog`}
      disableBackdropClick
      data-testid="warningDialog"
    >
      <DialogContent>
        <div className="content-wrapper">
          <p className="paragraph text-center">
            Please confirm that you want to <br />
            <span>
              {type == 'ENABLE'
                ? 'activate MFA for all portal users.'
                : 'deactivate MFA for all portal users.'}
            </span>
          </p>
        </div>
        <ul className="list-inline footer text-center">
          <li>
            <a
              className="secondary-btn"
              onClick={props.handleClose}
              data-testid="warningDialogCancelButton"
            >
              Cancel
            </a>
          </li>
          <li>
            <a
              className={`primary-btn ${actionLoader ? 'disabled' : ''}`}
              onClick={props.handleUpdateMfa}
              data-testid="warningDialogConfirmButton"
            >
              Confirm
            </a>
          </li>
        </ul>
      </DialogContent>
    </Dialog>
  );
}
