import React from 'react';
import { BoxV2 as Box, Flex } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquarePollHorizontal } from '@fortawesome/pro-regular-svg-icons';

export default function CampaignDescription(props) {
  const { campaign } = props;
  return (
    <div className="description details-wrapper" data-testid="campaignDetails">
      <div className="title">
        <h3 className="heading2">
          <Flex sx={{ alignItems: 'center', gap: 'xs' }}>
            <FontAwesomeIcon icon={faSquarePollHorizontal} size="lg" />
            <span>Campaign Details</span>
          </Flex>
        </h3>
      </div>
      <div className="content">
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '60% 40%',
            rowGap: 's',
            columnGap: 'xl',
          }}
        >
          <DetailField
            label="Campaign Description"
            value={campaign.description}
            data-testid="campaignDetailsDescription"
          />
          <DetailField
            label="Privacy Policy Link"
            value={campaign?.privacyPolicyLink}
            data-testid="campaignDetailsPrivacyPolicy"
          />
          <DetailField
            label="Call to Action / Message Flow"
            value={campaign.messageFlow}
            data-testid="campaignDetailsMessageFlow"
          />
          <DetailField
            label="Terms and Conditions Link"
            value={campaign?.termsAndConditionsLink}
            data-testid="campaignDetailsTermsAndConditions"
          />
        </Box>
      </div>
    </div>
  );
}

const DetailField = ({ label, value, ...props }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '190px 1fr',
        gap: 'l',
        fontSize: 'H300',
        fontWeight: 400,
        lineHeight: 'normal',
      }}
      {...props}
    >
      <label>{label}:</label>
      <Box
        sx={{
          fontWeight: 600,
          color: 't.black70',
          wordBreak: 'break-word',
        }}
      >
        {value || 'N/A'}
      </Box>
    </Box>
  );
};
