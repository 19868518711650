import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  makeStyles,
  TextField,
} from '@material-ui/core';

import { S3_ASSETS_PATH } from '../../../constants';
import { useState } from 'react';
import { isValidInput } from '../../../utils';

import { Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateRight } from '@fortawesome/pro-regular-svg-icons';

const useStyles = makeStyles({
  container: {
    maxWidth: 600,
  },
  content: {
    width: '100%',
    padding: '19px 20px 24px 20px !important',
    borderRadius: 4,
    boxSizing: 'border-box',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginBottom: 14,
    '& > img': {
      width: 20,
      height: 20,
      marginRight: 7,
      objectFit: 'cover',
    },
  },
  note: {
    marginBottom: 8,
    fontSize: 10,
    lineHeight: '12px',
    fontWeight: 400,
    color: '#49535D',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 16,
    width: '100%',
    marginTop: 26,
  },
  cancelButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 26,
    padding: '0 15px',
    border: '1px solid #00698F',
    borderRadius: 100,
    fontSize: 12,
    lineHeight: '14px',
    fontWeight: 400,
    color: '#00698F',
    background: '#FFFFFF',
    cursor: 'pointer',
    '&:disabled': {
      opacity: 0.4,
      cursor: 'not-allowed',
    },
  },
  liftSuspensionButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 26,
    padding: '0 15px',
    border: 'none',
    borderRadius: 100,
    fontSize: 12,
    lineHeight: '14px',
    fontWeight: 400,
    color: '#FFFFFF',
    background: '#00698F',
    cursor: 'pointer',
    '&:disabled': {
      opacity: 0.4,
      cursor: 'not-allowed',
    },
  },
});

const CampaignSuspendDialog = ({
  disabled,
  profile,
  campaign,
  carrier,
  onClose,
  onSubmit,
}) => {
  const classes = useStyles();
  const [explanation, setExplanation] = useState('');
  const [explanationError, setExplanationError] = useState('');
  const isSoleProprietor = campaign.usecase === 'SOLE_PROPRIETOR';
  const isRedacted = isSoleProprietor && !profile.dpaSigned;

  const validate = () => {
    if (explanation.length < 1) {
      setExplanationError('Please enter explanation');
      return false;
    } else if (explanation.length > 3000) {
      setExplanationError('Maximum 3000 chars are allowed');
      return false;
    } else if (!isValidInput(explanation)) {
      setExplanationError('Invalid input');
      return false;
    }
    return true;
  };

  const handleExplanationChange = (event) => {
    setExplanation(event.target.value);
    setExplanationError('');
  };

  const handleExplanationBlur = () => {
    validate();
  };

  const handleSubmit = () => {
    const valid = validate();
    if (valid) {
      onSubmit && onSubmit(explanation);
    }
  };

  return (
    <Dialog
      className="default-dialog"
      classes={{ paper: classes.container }}
      open
      disableBackdropClick
      fullWidth
      maxWidth="xs"
      aria-labelledby="responsive-dialog-title"
      onClose={onClose}
    >
      <DialogContent classes={{ root: classes.content }}>
        <div className={classes.title}>
          <Box margin={{ right: 'xs' }}>
            <FontAwesomeIcon icon={faRotateRight} size="lg" />
          </Box>
          Lift Suspension
        </div>
        <div className={classes.note}>* Indicates a Required Field</div>
        <div className="modal-content">
          <div className="form-group-field">
            <TextField
              InputLabelProps={{ shrink: true }}
              disabled
              fullWidth
              label="Campaign ID"
              value={campaign ? campaign.uid : 'N/A'}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 10,
            }}
            className="form-group-field"
          >
            <TextField
              InputLabelProps={{ shrink: true }}
              style={{ flex: 1 }}
              disabled
              fullWidth
              label="Brand ID"
              value={campaign ? campaign.brand.uid : 'N/A'}
            />
            <TextField
              InputLabelProps={{ shrink: true }}
              style={{ flex: 4 }}
              disabled
              fullWidth
              label="Brand Name"
              value={
                isRedacted
                  ? 'Redacted due to PII'
                  : campaign.brand.displayName || 'N/A'
              }
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 10,
            }}
            className="form-group-field"
          >
            <TextField
              InputLabelProps={{ shrink: true }}
              style={{ flex: 1 }}
              disabled
              fullWidth
              label="CSP ID"
              value={campaign ? campaign.brand.csp.uid : 'N/A'}
            />
            <TextField
              InputLabelProps={{ shrink: true }}
              style={{ flex: 4 }}
              disabled
              fullWidth
              label="CSP Name"
              value={campaign ? campaign.brand.csp.displayName : 'N/A'}
            />
          </div>
          <div className="form-group-field">
            <TextField
              InputLabelProps={{ shrink: true }}
              disabled
              fullWidth
              label="Carrier"
              value={
                carrier && carrier.rulesEngineResultsJson
                  ? carrier.rulesEngineResultsJson.mno || '--'
                  : '--'
              }
            />
          </div>
          <div className="form-group-field">
            <TextField
              InputLabelProps={{ shrink: true }}
              required
              fullWidth
              multiline
              rowsMax={4}
              error={!!explanationError}
              label="Explanation"
              value={explanation}
              onChange={handleExplanationChange}
              onBlur={handleExplanationBlur}
            />
            {explanationError ? (
              <h6 className="error-msg">{explanationError}</h6>
            ) : null}
          </div>
          <div className={classes.actions}>
            <button
              className={classes.cancelButton}
              disabled={disabled}
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className={classes.liftSuspensionButton}
              disabled={disabled || !!explanationError}
              onClick={handleSubmit}
            >
              Lift Suspension
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

CampaignSuspendDialog.propTypes = {
  disabled: PropTypes.bool,
  profile: PropTypes.shape({
    dpaSigned: PropTypes.bool.isRequired,
  }),
  campaign: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    brand: PropTypes.shape({
      uid: PropTypes.string.isRequired,
      displayName: PropTypes.string,
      csp: PropTypes.shape({
        uid: PropTypes.string.isRequired,
        displayName: PropTypes.string,
      }).isRequired,
    }).isRequired,
    usecase: PropTypes.string.isRequired,
  }),
  carrier: PropTypes.shape({
    rulesEngineResultsJson: PropTypes.string,
  }),
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default CampaignSuspendDialog;
