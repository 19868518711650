import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, TextField } from '@material-ui/core';
import { S3_ASSETS_PATH } from '../../../constants';
import { globalPutService } from '../../../utils/globalApiServices';
import { toastFlashMessage, isValidInput } from '../../../utils';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/pro-regular-svg-icons';

export default function AddNewApiKeyDialog(props) {
  const { type, open, account } = props;

  const [loader, setLoader] = useState(false);
  const [error, setError] = useState({});
  const [label, setLabel] = useState('');
  useEffect(() => {
    if (open) {
      setLabel(account.displayName);
      setError({});
      setLoader(false);
    }
  }, [open]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (type == 'add') {
      // props.fetchApiAccounts()
      props.handleClose();
    } else {
      setLoader(true);
      let validateNewInput = {
        label:
          label && label.trim().length
            ? label.trim().length > 20
              ? 'Maximum 20 chars allowed'
              : isValidInput(label.trim())
              ? ''
              : 'Invalid input'
            : 'Please enter Label',
      };
      if (
        Object.keys(validateNewInput).every((k) => {
          return validateNewInput[k] === '';
        })
      ) {
        globalPutService(`dca/api/account/${account.id}`, {
          displayName: label.trim(),
        }).then((response) => {
          setLoader(false);
          if (response.status >= 200 && response.status < 300) {
            toastFlashMessage(`API Key updated successfully`, 'success');
            props.setAccount(response.data);
            props.handleClose();
          }
        });
      } else {
        setLoader(false);
        setError(validateNewInput);
      }
    }
  };
  return (
    <Dialog
      open={open}
      aria-labelledby="responsive-dialog-title"
      // maxWidth="xs"
      fullWidth={true}
      id="successModal"
      className="api-action-dialog default-dialog add-new-api-key"
      data-testid="addNewApiKeyDialog"
    >
      <DialogContent className="content-wrapper">
        <form onSubmit={handleSubmit}>
          <h3 className="title heading2 text-center">
            <Box flexDirection="row" justifyContent="center">
              <Box margin={{ right: 'xs' }}>
                <FontAwesomeIcon icon={faKey} />
              </Box>
              <span>
                {type == 'add' ? `New API Key Created` : `Edit API Key`}
              </span>
            </Box>
          </h3>
          <div
            className="form-group-field copy-btn-wrapper"
            data-testid="addNewApiKeyDialogApiKey"
          >
            <TextField label="Key" value={account.apiKey} disabled />
            {/* {error.key ? <h6 className="error-msg">{error.key}</h6> : ''} */}
            <CopyToClipboard
              text={account.apiKey}
              onCopy={() =>
                toastFlashMessage(
                  'API Key has been copied to your clipboard',
                  'success'
                )
              }
            >
              <span className="copy-btn secondary-btn">Copy</span>
            </CopyToClipboard>
          </div>
          <div
            className="form-group-field copy-btn-wrapper"
            data-testid="addNewApiKeyDialogSecret"
          >
            <TextField label="Secret" value={account.secret} disabled />
            <CopyToClipboard
              text={account.secret}
              onCopy={() =>
                toastFlashMessage(
                  'Secret has been copied to your clipboard',
                  'success'
                )
              }
            >
              <span className="copy-btn secondary-btn">Copy</span>
            </CopyToClipboard>
            {/* {error.secret ? <h6 className="error-msg">{error.secret}</h6> : ''} */}
          </div>
          <div className="form-group-field">
            <TextField
              data-testid="addNewApiKeyDialogLabel"
              error={error.label ? true : false}
              label="Label"
              value={label}
              disabled={type == 'add' ? true : false}
              onChange={(e) => {
                setLabel(e.target.value);
                setError({ ...error, label: '' });
              }}
            />
            {error.label ? <h6 className="error-msg">{error.label}</h6> : ''}
          </div>
          <ul className="list-inline footer">
            {type == 'edit' ? (
              <li>
                <a
                  data-testid="addNewApiKeyDialogCancelButton"
                  className={`secondary-btn ${loader ? '' : ''}`}
                  onClick={() => props.handleClose(false)}
                >
                  Cancel
                </a>
              </li>
            ) : null}
            <li className="form-group-field">
              <input
                type="submit"
                data-testid="addNewApiKeyDialogSubmitButton"
                disabled={
                  loader || Object.keys(error).find((k) => error[k] != '')
                }
                value={type == 'add' ? 'Close' : 'Save'}
              />
            </li>
          </ul>
        </form>
      </DialogContent>
    </Dialog>
  );
}
