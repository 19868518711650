import { ErrorBoundaryHoc, PageLayoutHoc, RequiredAuth } from '../../hocs';
import Settings from './containers/Settings';

export const settingsRoutes = [
  {
    path: '/settings',
    component: RequiredAuth(
      PageLayoutHoc(ErrorBoundaryHoc(Settings), {
        title: 'SETTINGS',
        id: 'VIEW_DCA_RULES',
      })
    ),
  },
];
