import { DEV_BASE_URL } from '../constants';
import axios from 'axios';
import { toastFlashMessage } from '.';

const axiosInstance = axios.create();
axiosInstance.defaults.baseURL = DEV_BASE_URL;
axiosInstance.interceptors.request.use(
  function (config) {
    const oktaTokenStorage = JSON.parse(
      localStorage.getItem('okta-token-storage')
    );
    if (oktaTokenStorage) {
      config.headers.Authorization = `Bearer ${oktaTokenStorage.accessToken.accessToken}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    if (response.status >= 200 && response.status < 300) {
      response.ok = true;
    }
    return response;
  },
  function (error) {
    // console.log('Api error', error.response)
    if (error && error.response && error.response.status < 500) {
      if (error.response?.data?.error) {
        const keyName = Object.keys(error.response.data.error)[0];
        // const errorMsg = keyName ? `${keyName} ${error.response.data.error[keyName][0]}`: error.response.data.error[keyName][0]
        const errorMsg = error.response.data.error[keyName][0];
        if (
          errorMsg &&
          errorMsg.includes(
            'HTTP 400, Okta E0000001 (Api validation failed: password - password: Password requirements were not met. Password requirements: at least 12 characters, a lowercase letter, an uppercase letter, a number, a symbol, no parts of your username, does not include your first name, does not include your last name. Your password cannot be any of your last 5 passwords.)'
          )
        ) {
          toastFlashMessage(`Password requirements are not met`, 'error');
        } else {
          toastFlashMessage(errorMsg, 'error');
        }
      }
    } else {
      toastFlashMessage(
        'Unable to process the request due to technical error',
        'error'
      );
    }
    return error.response;
  }
);

export default axiosInstance;
