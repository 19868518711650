import React, { useState, useEffect } from 'react';
import { Flex, BoxV2 as Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPen,
  faEnvelope,
  faMessageDots,
} from '@fortawesome/pro-regular-svg-icons';
import EditEmailModal from '../EditEmailModal';
import Field from './Field';

const EmailPreferences: React.FC<{
  editable: boolean;
  loading: boolean;
  emails: string[];
  onChange?: (emails: string[]) => void;
}> = ({ editable, loading, emails, onChange }) => {
  const [openEdit, setOpenEdit] = useState(false);

  const handleEditEmails = (updatedEmails: string[]) => {
    onChange && onChange(updatedEmails);
    setOpenEdit(false);
  };

  return (
    <Box
      sx={{
        mt: 'xxl',
        p: 'xxl',
        border: '1px solid #C0CCD4',
        fontFamily: 'normal',
        color: 't.grey500',
        lineHeight: 'normal',
      }}
    >
      <Flex
        sx={{
          justifyContent: 'space-between',
          fontSize: 'H300',
          mb: 'xl',
        }}
      >
        <Flex
          sx={{
            alignItems: 'center',
            fontSize: 'H500',
            fontWeight: 600,
          }}
        >
          <FontAwesomeIcon
            icon={faEnvelope}
            style={{ marginRight: '8px', fontSize: '24px' }}
          />
          <Box sx={{ fontSize: 'H500' }} as="h2">
            Email Preferences
          </Box>
        </Flex>
        {editable && (
          <EditButton disabled={loading} onClick={() => setOpenEdit(true)} />
        )}
      </Flex>
      <Flex sx={{ fontSize: 'H400', alignItems: 'center' }} as="h3">
        <FontAwesomeIcon
          icon={faMessageDots}
          style={{ marginRight: '8px', fontSize: '18px' }}
        />
        System Notification Email
      </Flex>
      <Box
        sx={{
          fontSize: 'H300',
          py: 'xs',
          borderBottom: '1px solid',
          borderColor: '#CCCFD0',
        }}
      >
        Used for system event notification.
      </Box>
      <Box
        sx={{
          fontSize: 'H300',
          fontWeight: 400,
          display: 'grid',
          gridTemplateColumns: '50% 50%',
          py: 's',
          rowGap: 's',
        }}
      >
        {loading && <Field label="Email Address" loading={loading} value="" />}
        {emails.map((email, index) => (
          <Field
            key={index}
            label="Email Address"
            value={email}
            data-testid="emailField"
          />
        ))}
      </Box>

      <EditEmailModal
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        emails={emails}
        onSave={handleEditEmails}
      />
    </Box>
  );
};

const EditButton: React.FC<{ disabled: boolean; onClick: () => void }> = ({
  disabled,
  onClick,
}) => {
  return (
    <Flex
      role="button"
      data-testid="editEmailPreferencesButton"
      sx={{
        color: '#19B4A7',
        cursor: disabled ? 'not-allowed' : 'pointer',
        alignItems: 'center',
        gap: 'xxs',
        opacity: disabled ? 0.5 : 1,
      }}
      aria-disabled={disabled}
      onClick={() => !disabled && onClick()}
    >
      <FontAwesomeIcon icon={faPen} />
      Edit
    </Flex>
  );
};

export default EmailPreferences;
