import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { S3_ASSETS_PATH } from '../../../constants';

const ResetEmailSuccess = (props) => {
  return (
    <div className="campaign-registry" data-testid="resetEmailSuccess">
      <div item className="content-blk">
        <img
          src={`${S3_ASSETS_PATH}/images/MNO/thankyou-mno.svg`}
          alt="logo"
        ></img>
        <h3 className="heading2">Thank You!</h3>
        <p className="heading2 text">
          A reset password link has been sent to your registered email address.
          <br />
          <br /> Please click on the link in the email to reset your password.
        </p>
      </div>
      <div item className="link">
        <Link
          to="/login"
          className="primary-btn"
          data-testid="resetEmailSuccessToLoginButton"
        >
          Back to Login
        </Link>
      </div>
    </div>
  );
};

export default ResetEmailSuccess;
