// src/App.js

import React, { useEffect } from 'react';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  Redirect,
} from 'react-router-dom';
import { Security, SecureRoute } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';
import { datadogRum } from '@datadog/browser-rum';
import { authRoutes } from './features/auth/authRoutes';
import { dashboardRoutes } from './features/dashboard/dasbhhboardRoutes';
import { campaignsRoutes } from './features/campaigns/campaignsRoutes';
import { settingsRoutes } from './features/settings/settingsRoutes';
import PageNotFound from './404';
import { OKTA_CLIENT_ID, OKTA_ISSUER_URL } from './constants';
import CustomLoginCallback from './CustomLoginCallback';
import { integrationRoutes } from './features/integrations/integrationRoutes';
import { usersRoutes } from './features/users/usersRoutes';
import { eventsRoutes } from './features/events/eventsRoutes';

const oktaAuth = new OktaAuth({
  issuer: OKTA_ISSUER_URL,
  clientId: OKTA_CLIENT_ID,
  redirectUri: window.location.origin + '/implicit/callback',
  pkce: false,
  postLogoutRedirectUri: window.location.origin + '/login',
});

const AppWithRouterAccess = () => {
  const history = useHistory();
  const location = useLocation();

  const onAuthRequired = () => {
    history.push('/login');
  };

  const computeViewName = (routeMatches) => {
    let viewName = '';
    for (let index = 0; index < routeMatches.length; index++) {
      const routeMatch = routeMatches[index];
      const { path } = routeMatch;

      if (!path) {
        return null;
      }

      if (path.startsWith('/')) {
        viewName = path;
      } else {
        viewName += viewName.endsWith('/') ? path : `/${path}`;
      }
    }

    return viewName || '/';
  };

  useEffect(() => {
    const allRoutes = [
      ...authRoutes,
      ...dashboardRoutes,
      ...campaignsRoutes,
      ...settingsRoutes,
      ...integrationRoutes,
      ...usersRoutes,
      ...eventsRoutes,
    ];
    const routeMatches = allRoutes.filter((route) => {
      const regex = new RegExp(route.path.replace(/:[^\/]+/g, '\\w+'));
      return regex.test(location.pathname);
    });
    const viewName = routeMatches && computeViewName(routeMatches);
    if (viewName) {
      datadogRum.startView({ name: viewName });
    }
  }, [location.pathname]);

  return (
    <Security oktaAuth={oktaAuth} onAuthRequired={onAuthRequired}>
      <Switch>
        <Route
          exact
          path="/implicit/callback"
          // component={LoginCallback}
          component={CustomLoginCallback}
          key="callback"
        />
        {authRoutes.map(({ path, component, key }, index) => (
          <Route exact path={path} component={component} key={key} />
        ))}
        {[
          ...dashboardRoutes,
          ...campaignsRoutes,
          ...settingsRoutes,
          ...eventsRoutes,
          ...integrationRoutes,
          ...usersRoutes,
        ].map(({ path, component, key }) => (
          <SecureRoute
            exact
            path={path}
            component={component}
            key={key}
            id={key}
          />
        ))}
        <Route path="/404" component={PageNotFound} key="404" />
        <Redirect from="*" to="/404" />
      </Switch>
    </Security>
  );
};
export default AppWithRouterAccess;
