import { Box, Table } from 'portal-commons';
import PropTypes from 'prop-types';
import React from 'react';
import { faCalendarRange } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import EventsListingRow from './EventsListingRow';

const headRows = [
  {
    id: 'eventType',
    label: 'EVENT TYPE',
    sortable: false,
  },
  {
    id: 'categoryType',
    label: 'SOURCE',
    sortable: false,
  },
  {
    id: 'campaignUid',
    label: 'CAMPAIGN ID',
    sortable: false,
  },
  {
    id: 'brandUid',
    label: 'BRAND ID',
    sortable: false,
  },
  {
    id: 'updateDate',
    label: 'DATE & TIME',
    sortable: false,
  },
  {
    id: 'description',
    label: 'DESCRIPTION',
    sortable: false,
  },
];

const CampaignEventHistory = ({ loading, eventInfo, onPageChange }) => {
  const handlePageChange = (page) => {
    onPageChange && onPageChange(page);
  };

  return (
    <div
      className="campaign-events details-wrapper"
      data-testid="campaignEventHistory"
    >
      <div className="title">
        <h3 className="heading2">
          <Box flexDirection="row" alignItems="center">
            <Box margin={{ right: 'xs' }}>
              <FontAwesomeIcon icon={faCalendarRange} />
            </Box>
            <span>Events</span>
          </Box>
        </h3>
      </div>
      <Table
        testId="campaignEventHistoryTable"
        disableHover
        loading={loading}
        headRows={headRows}
        emptyState="no events to view"
        records={{ total: eventInfo.totalRecords }}
        tableData={eventInfo.records.map((record, index) => {
          return <EventsListingRow key={index} event={record} />;
        })}
        pagination={{
          count: Math.ceil(eventInfo.totalRecords / eventInfo.recordsPerPage),
          rowsPerPage: eventInfo.recordsPerPage,
          page: eventInfo.page,
          totalRecords: eventInfo.totalRecords,
        }}
        handleChangePage={handlePageChange}
      />
    </div>
  );
};

CampaignEventHistory.propTypes = {
  loading: PropTypes.bool,
  eventInfo: PropTypes.shape({}).isRequired,
  onPageChange: PropTypes.func,
};

export default CampaignEventHistory;
