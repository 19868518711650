import regexExpressions from '../../../../constants/regExpressions';
import { RegisterOptions } from 'react-hook-form';
import { MAX_EMAIL_LENGTH, MAX_NAME_LENGTH } from '../../constants';

export interface ValidateRules {
  [key: string]: RegisterOptions;
}

export const validateRules: ValidateRules = {
  firstName: {
    required: 'Please enter First Name',
    maxLength: {
      value: MAX_NAME_LENGTH,
      message: `Maximum ${MAX_NAME_LENGTH} chars are allowed`,
    },
    pattern: {
      value: regexExpressions.name,
      message: 'Invalid First Name',
    },
  },
  lastName: {
    required: 'Please enter Last Name',
    maxLength: {
      value: MAX_NAME_LENGTH,
      message: `Maximum ${MAX_NAME_LENGTH} chars are allowed`,
    },
    pattern: {
      value: regexExpressions.name,
      message: 'Invalid Last Name',
    },
  },
  email: {
    required: 'Please enter Email Address',
    maxLength: {
      value: MAX_EMAIL_LENGTH,
      message: `Maximum ${MAX_EMAIL_LENGTH} chars are allowed`,
    },
    pattern: {
      value: regexExpressions.email,
      message: 'Invalid Email',
    },
  },
  userAppRole: {
    required: 'Please select Role',
  },
};
