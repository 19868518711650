import React from 'react';
import { Grid } from '@material-ui/core';

export default function DetailItem(props) {
  return (
    <Grid
      container
      spacing={3}
      className="detail-item"
      data-testid={`detailItem-${props.title.replace(/\s/g, '')}`}
    >
      <Grid item xs={props.xs ? props.xs : 4}>
        <h3 className="paragraph">{props.title}:</h3>
      </Grid>
      <Grid item xs={props.xs ? 12 - props.xs : 8}>
        <h3 className="paragraph medium">
          {props.value ? props.value : 'N/A'}
        </h3>
      </Grid>
    </Grid>
  );
}
