import { ErrorBoundaryHoc, PageLayoutHoc, RequiredAuth } from '../../hocs';
import Dashboard from './containers/Dashboard';

export const dashboardRoutes = [
  {
    path: '/dashboard',
    component: RequiredAuth(
      PageLayoutHoc(ErrorBoundaryHoc(Dashboard), {
        title: 'DASHBOARD',
        id: 'DASHBOARD',
      })
    ),
    key: 'login',
  },
];
