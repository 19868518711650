import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { Dialog, DialogContent, Grid } from '@material-ui/core';
import {
  globalPutService,
  globalDeleteService,
} from '../../../utils/globalApiServices';
import { fieldValidation } from '../../../utils/validator';
import { Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareXmark } from '@fortawesome/pro-regular-svg-icons';

export default function CampaignsActionDialog(props) {
  const { open, type, campaignUid, brandName, cspUid } = props;
  const [loader, setLoader] = useState(false);
  const [explanation, setExplanation] = useState('');
  const [error, setError] = useState({});
  const [errorCodes, setErrorCodes] = useState({
    campaignRejectionDescription: {
      0: '',
      1: 'Please enter Explanation',
      3: 'Maximum 2048 chars are allowed',
      5: 'Invalid input',
    },
    campaignRejectionDescriptionObj: {
      requiredFlag: true,
      maxLength: 2048,
      xssProtection: true,
    },
  });

  useEffect(() => {
    if (open) {
      setError({});
      setExplanation('');
    }
  }, [open]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    if (type === 'accept') {
      globalPutService(`dca/campaigns/primaryDca/${campaignUid}`).then(
        (response) => {
          setLoader(false);
          if (response.status >= 200 && response.status < 300) {
            props.handleClose(true);
          }
        }
      );
    } else {
      const updatedExplanation = explanation.trim();
      const validateNewInput = {
        explanation:
          errorCodes['campaignRejectionDescription'][
            fieldValidation({
              ...errorCodes['campaignRejectionDescriptionObj'],
              fieldval: updatedExplanation,
            })
          ],
      };
      if (
        Object.keys(validateNewInput).every((k) => {
          return validateNewInput[k] === '';
        })
      ) {
        globalDeleteService(`dca/campaigns/primaryDca/${campaignUid}`, {
          explanation: updatedExplanation,
        }).then((response) => {
          setLoader(false);
          if (response.status >= 200 && response.status < 300) {
            props.handleClose(true);
          }
        });
      } else {
        setLoader(false);
        setError(validateNewInput);
      }
    }
  };
  return (
    <Dialog
      open={open}
      onClose={() => props.handleClose(false)}
      aria-labelledby="responsive-dialog-title"
      maxWidth="xs"
      fullWidth={true}
      className={`campaigns-action-dialog default-dialog`}
      disableBackdropClick
      data-testid="campaignActionDialog"
    >
      <DialogContent className={''}>
        <div className="content-wrapper text-center">
          {type == 'accept' ? (
            <p className="paragraph">
              {' '}
              You are accepting the election as a Primary DCA for this campaign.
              You shall provision it to the MNOs for which you have a direct
              connection and elect Secondary DCAs for the remaining MNOs.
            </p>
          ) : (
            <>
              <h6 className="heading1 text-center title">
                <Box
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box margin={{ right: 'xs' }}>
                    <FontAwesomeIcon icon={faSquareXmark} size="lg" />
                  </Box>
                  Decline Campaign
                </Box>
              </h6>
              <div className="form-group-field">
                <TextField
                  id="campaignUid"
                  data-testid="campaignActionDialogCampaignUidInput"
                  label="Campaign ID*"
                  value={campaignUid}
                  fullWidth={true}
                  placeholder="Campaign ID*"
                  inputProps={{ 'aria-label': 'campaignUid' }}
                  disabled
                />
              </div>
              <div className="form-group-field">
                <TextField
                  id="Brandname"
                  data-testid="campaignActionDialogBrandNameInput"
                  label="Brand Name*"
                  value={brandName}
                  fullWidth={true}
                  placeholder="Brand Name*"
                  inputProps={{ 'aria-label': 'Brandname' }}
                  disabled
                  multiline
                />
              </div>
              <div className="form-group-field">
                <TextField
                  id="cspUid"
                  data-testid="campaignActionDialogCspUidInput"
                  label="CSP ID*"
                  value={cspUid}
                  fullWidth={true}
                  placeholder="CSP ID*"
                  inputProps={{ 'aria-label': 'cspUid' }}
                  disabled
                />
              </div>
              <div className="form-group-field">
                <TextField
                  error={error.explanation ? true : false}
                  value={explanation}
                  id="explanation"
                  data-testid="campaignActionDialogExplanationInput"
                  placeholder="Explanation*"
                  label="Explanation*"
                  fullWidth={true}
                  inputProps={{ 'aria-label': 'explanation' }}
                  onChange={(event) => {
                    setError('');
                    setExplanation(event.target.value);
                  }}
                  onBlur={(event) => {
                    setError('');
                    setExplanation(event.target.value);
                  }}
                />
                {error.explanation ? (
                  <h6 className="error-msg">{error.explanation}</h6>
                ) : null}
              </div>
            </>
          )}
        </div>
        <ul className="list-inline footer text-center">
          <li>
            <p>
              <a
                className="secondary-btn"
                data-testid="campaignActionDialogCancelButton"
                onClick={() => {
                  setExplanation('');
                  props.handleClose(false);
                }}
              >
                Cancel
              </a>
            </p>
          </li>
          <li>
            <p>
              <a
                className={`primary-btn ${loader ? 'disabled' : ''}`}
                onClick={handleSubmit}
                data-testid="campaignActionDialogAcceptButton"
              >
                {type == 'accept' ? 'Proceed' : 'Decline'}
              </a>
            </p>
          </li>
        </ul>
      </DialogContent>
    </Dialog>
  );
}
