import React, { Component } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import { withRouter, Redirect } from 'react-router-dom';
import { toastFlashMessage } from '../utils';

async function checkUser() {
  if (this.props.authState.isAuthenticated && !this.state.userInfo) {
    const userInfo = await this.props.oktaAuth.getUser();
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
    this.setState({ userInfo });
  }
}

function RequiredAuth(ComposedComponent) {
  class RequiredAuth extends Component {
    constructor(props) {
      super(props);
      this.state = {
        userInfo: null,
        userDetail: localStorage.getItem('okta-token-storage')
          ? JSON.parse(localStorage.getItem('okta-token-storage'))
          : {},
      };
      this.checkUser = checkUser.bind(this);
    }

    componentDidMount() {
      this.checkUser();
      const userDetail = JSON.parse(localStorage.getItem('okta-token-storage'));
      if (userDetail) {
        if (typeof userDetail === 'object') {
          const details = Object.keys(userDetail);
          if (details.length) {
            if (userDetail.accessToken && userDetail.idToken) {
            } else {
              localStorage.clear();
              setTimeout(() => {
                this.props.history.push('/login');
              }, 1000);
            }
          } else {
            localStorage.clear();
            setTimeout(() => {
              this.props.history.push('/login');
            }, 1000);
          }
        }
      } else {
        localStorage.clear();
        setTimeout(() => {
          this.props.history.push('/login');
        }, 1000);
      }
    }

    render() {
      const { userDetail } = this.state;
      if (!(userDetail.idToken && userDetail.accessToken)) {
        toastFlashMessage('Please login again', 'success');
        localStorage.clear();
        return <Redirect to="/login" />;
      } else {
        return <ComposedComponent />;
      }
    }
  }
  return withOktaAuth(withRouter(RequiredAuth));
}
export default RequiredAuth;
