import React from 'react';
import Grid from '@material-ui/core/Grid';
import { ForgotPasswordForm } from '../components';
import '../../../assets/styles/auth-module.scss';

export default function ForgotPassword(props) {
  return (
    <section className="user-authentication-section">
      <Grid
        container
        spacing={0}
        alignItems="center"
        justify="center"
        className="user-form-wrapper"
        style={{ height: window.innerHeight - 80 }}
      >
        <ForgotPasswordForm />
      </Grid>
    </section>
  );
}
