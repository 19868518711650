import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { sortMnoList } from '../../../utils';
import NetworkItem from './NetworkItem';
import {
  globalGetService,
  globalPutService,
} from '../../../utils/globalApiServices';
import CampaignsActionDialog from './CampaignsActionDialog';
import { acceptPrimaryDcaCampaign, rejectPrimaryDcaCampaign } from '../apis';
import CampaignsByPrimaryDcaActionDialog from './CampaignsByPrimaryDcaActionDialog';

import { Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareNodes } from '@fortawesome/pro-regular-svg-icons';

export default function CampaignCarrierStatus(props) {
  const {
    profile,
    campaign,
    onDcaMenuClick,
    provisional,
    existingChain,
    onAcceptElection,
  } = props;
  const [campaignOperations, setCampaignOperations] = useState([]);
  const [loader, setLoader] = useState(true);
  const [cnpNominationPopup, setCnpNominationPopup] = useState(false);
  const [actionLoader, setActionLoader] = useState(false);
  const [reject, setReject] = useState(false);
  const history = useHistory();
  const wideButton = campaignOperations.some((carrier) => {
    if (profile && carrier.dcas && carrier.status) {
      return (
        carrier.dcas.some((dca) => profile.uid === dca.id) &&
        carrier.status.toLowerCase() === 'suspended'
      );
    }
    return false;
  });

  const fetchCampaignOperations = (campaignUid, provisional) => {
    globalGetService(`dca/campaigns/${campaignUid}/operations`, {
      provisional,
    }).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        setLoader(false);
        const result = response.data.map((data) => ({
          ...data,
          rulesEngineResultsJson: data.rulesEngineResultsJson
            ? JSON.parse(data.rulesEngineResultsJson)
            : data.rulesEngineResultsJson,
        }));
        setCampaignOperations(result);
      }
    });
  };

  useEffect(() => {
    if (
      campaign.isPrimaryDca
        ? campaign.sharingStatus === 'PENDING'
        : campaign.secondarySharingStatus === 'PENDING'
    ) {
      setCnpNominationPopup(true);
    } else if (provisional != undefined) {
      setCnpNominationPopup(false);
      fetchCampaignOperations(campaign.uid, provisional);
    }
  }, [campaign, provisional]);

  const handleUpdateCampaignOperations = () => {
    fetchCampaignOperations(campaign.uid);
  };
  const handleCnpAction = (type) => {
    if (type === 'accept') {
      setActionLoader(true);
      if (campaign.isPrimaryDca) {
        globalPutService(`dca/campaigns/primaryDca/${campaign.uid}`).then(
          (response) => {
            setActionLoader(false);
            if (response.status >= 200 && response.status < 300) {
              setLoader(true);
              setCnpNominationPopup(false);
              onAcceptElection();
            }
          }
        );
      } else {
        acceptPrimaryDcaCampaign(campaign.uid).then((response) => {
          setActionLoader(false);
          setLoader(true);
          setCnpNominationPopup(false);
          onAcceptElection();
        });
      }
    } else {
      setReject(true);
    }
  };

  const handleClose = (success = '') => {
    setReject(false);
    if (campaign.isPrimaryDca && success) {
      const backUrl = props.goBackPage || '/csp-campaigns';
      history.push(backUrl);
    }
  };

  const handleSubmit = async (explanation) => {
    setActionLoader(true);
    try {
      await rejectPrimaryDcaCampaign(campaign.uid, explanation);
      setActionLoader(false);
      history.push('/dca-campaigns');
    } catch (e) {}
  };

  return (
    <div
      className={`carrier-status details-wrapper ${
        cnpNominationPopup ? 'blur-bg' : ''
      }`}
      data-testid="campaignCarrierStatus"
    >
      <div className="title">
        <h3 className="heading2">
          <Box flexDirection="row">
            <Box margin={{ right: 'xs' }}>
              <FontAwesomeIcon icon={faShareNodes} size="lg" />
            </Box>
            <span>
              {campaign.isPrimaryDca
                ? `Carrier status`
                : `Elected for the following MNOs:`}
            </span>
          </Box>
        </h3>
      </div>
      {loader ? (
        'Loading...'
      ) : (
        <div className="custom-scrollbar">
          <ul className="list-unstyled campaign-operations">
            {campaignOperations
              ? sortMnoList(campaignOperations, [
                  'rulesEngineResultsJson',
                  'mno',
                ]).map((carrier, index) =>
                  carrier.rulesEngineResultsJson &&
                  carrier.rulesEngineResultsJson.qualify &&
                  carrier.rulesEngineResultsJson.mnoSupport ? (
                    <li
                      key={index}
                      className="network-item-wrapper"
                      data-testid="campaignCarrierStatusNetworkRow"
                    >
                      <NetworkItem
                        wideButton={wideButton}
                        profile={profile}
                        campaign={campaign}
                        carrier={carrier}
                        key={index}
                        handleUpdateCampaignOperations={
                          handleUpdateCampaignOperations
                        }
                        role={props.role}
                        existingChain={existingChain}
                        provisional={provisional}
                        onDcaMenuClick={onDcaMenuClick}
                      />
                    </li>
                  ) : null
                )
              : null}
          </ul>
        </div>
      )}
      {cnpNominationPopup ? (
        <div
          className="cnp-nomination-wrapper"
          data-testid="cnpNominationPopup"
        >
          <div className="content-wrapper">
            <p className="paragraph text-center">
              You have been elected as a <span>Connectivity Partner</span> for
              this
              <br />
              campaign.
              <br />
              {campaign.isPrimaryDca && (
                <>
                  If you accept the election, please remember to assign
                  <br />a DCA <span>for each MNO</span>.
                </>
              )}
            </p>
            <ul
              className={`list-inline footer ${
                props.role == 'ROLE_REVIEWER' ? 'disabled' : ''
              }`}
            >
              <li>
                <p>
                  <a
                    className={`secondary-btn ${
                      actionLoader ? 'disabled' : ''
                    }`}
                    onClick={() => handleCnpAction('reject')}
                    data-testid="cnpNominationPopupDeclineButton"
                  >
                    Decline
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <a
                    className={`primary-btn ${actionLoader ? 'disabled' : ''}`}
                    onClick={() => handleCnpAction('accept')}
                    data-testid="cnpNominationPopupAcceptButton"
                  >
                    Accept
                  </a>
                </p>
              </li>
            </ul>
          </div>
        </div>
      ) : null}
      {campaign.isPrimaryDca ? (
        <CampaignsActionDialog
          open={reject}
          handleClose={handleClose}
          type={'reject'}
          campaignUid={campaign.uid}
          brandName={campaign.brandName}
          cspUid={campaign.cspUid}
        />
      ) : (
        <CampaignsByPrimaryDcaActionDialog
          type="reject"
          open={reject}
          disabled={actionLoader}
          onClose={handleClose}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
}
