import React from 'react';
import { Grid } from '@material-ui/core';
import DetailItem from './DetailItem';

import { Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser } from '@fortawesome/pro-regular-svg-icons';

export default function CampaignCspDetails(props) {
  const { campaign } = props;
  return (
    <div className="csp details-wrapper">
      <div className="title">
        <h3 className="heading2">
          <Box flexDirection="row" alignItems="center">
            <Box margin={{ right: 'xs' }}>
              <FontAwesomeIcon icon={faCircleUser} size="xl" />
            </Box>
            <span>CSP Details</span>
          </Box>
        </h3>
      </div>
      <Grid container columns={{ xs: 6 }}>
        <Grid item xs={6}>
          <DetailItem
            title="Legal Company Name"
            xs={5}
            value={campaign.brand.csp.companyName}
          />
          <DetailItem title="CSP EIN" xs={5} value={campaign.brand.csp.ein} />
          <DetailItem title="CSP ID" xs={5} value={campaign.brand.csp.uid} />
        </Grid>
        <Grid item xs={6}>
          <DetailItem
            title="Brand Name or DBA"
            xs={5}
            value={campaign.brand.csp.displayName}
          />
          <DetailItem
            title="CSP Universal EIN"
            xs={5}
            value={campaign.brand.csp.universalEin}
          />
        </Grid>
      </Grid>
    </div>
  );
}
