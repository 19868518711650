import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TableCell, TableRow } from '@material-ui/core';
import { convertTimeWithTimezone } from '../../../utils/time';
import { remapTCRStatus } from '../utils';

import { Box } from 'portal-commons';
import { getCampaignStatusIcon } from '../../../utils/statusIcon';

export default function CampaignsByPrimaryDcasListingRow(props) {
  let history = useHistory();
  let location = useLocation();
  const { campaign } = props;

  return (
    <TableRow
      data-testid="campaignsByPrimaryDcasListingRow"
      style={{ cursor: 'pointer' }}
      onClick={() =>
        history.push({
          pathname: `/csp-campaigns/${campaign.uid}`,
          state: {
            goBackPage: `${location.pathname}${
              location.search ? location.search : ''
            }`,
          },
        })
      }
    >
      <TableCell
        align="left"
        style={{ minWidth: 70 }}
        data-testid="campaignsByPrimaryDcasListingRowCampaignUid"
      >
        <span>{campaign.uid ? campaign.uid : campaign.id}</span>
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 70 }}
        data-testid="campaignsByPrimaryDcasListingRowBrandUid"
      >
        <span>{campaign.brandUid}</span>
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 130, maxWidth: 180 }}
        className="text-trucate"
        data-testid="campaignsByPrimaryDcasListingRowBrandName"
      >
        <span>{campaign.brandName || 'N/A'}</span>
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 110 }}
        data-testid="campaignsByPrimaryDcasListingRowCspName"
      >
        {campaign.cspName || 'N/A'}
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 110 }}
        data-testid="campaignsByPrimaryDcasListingRowDownStreamCnpName"
      >
        {campaign.downstreamCnpName || 'N/A'}
      </TableCell>
      <TableCell
        align="left"
        style={{}}
        data-testid="campaignsByPrimaryDcasListingRowUsecase"
      >
        {campaign.usecase || 'N/A'}
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 85 }}
        data-testid="campaignsByPrimaryDcasListingRowShareOnDate"
      >
        {convertTimeWithTimezone(campaign.sharedOnDate, 'MM/DD/YYYY')}
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 81 }}
        data-testid="campaignsByPrimaryDcasListingRowStatus"
      >
        {remapTCRStatus(campaign.status)}
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 230, paddingRight: 0 }}
        data-testid="campaignsByPrimaryDcasListingRowAction"
      >
        <div className="campaign-status-wrapper flex-centered">
          <h3 className="paragraph medium">
            <Box flexDirection="row" alignItems="center">
              <Box margin={{ right: 'xxs' }}>
                {getCampaignStatusIcon(campaign.sharingStatus?.toLowerCase())}
              </Box>
              <span>{campaign.sharingStatus?.toLowerCase()}</span>
            </Box>
          </h3>
          {campaign.sharingStatus == 'PENDING' &&
          campaign.status != 'EXPIRED' ? (
            <div
              className={`action-buttons-wrapper ${
                props.role == 'ROLE_REVIEWER' ? 'disabled' : ''
              }`}
            >
              <ul className="list-inline">
                <li style={{ marginLeft: 0 }}>
                  <p>
                    <a
                      className="primary-btn"
                      data-testid="campaignsByPrimaryDcasListingRowAcceptButton"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        props.handleActionClick('accept', campaign.uid);
                      }}
                    >
                      Accept
                    </a>
                  </p>
                </li>
                <li style={{ marginLeft: 4 }}>
                  <p>
                    <a
                      className="secondary-btn"
                      data-testid="campaignsByPrimaryDcasListingRowDeclineButton"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        props.handleActionClick(
                          'reject',
                          campaign.uid,
                          campaign
                        );
                      }}
                    >
                      Decline
                    </a>
                  </p>
                </li>
              </ul>
            </div>
          ) : null}
        </div>
      </TableCell>
    </TableRow>
  );
}
