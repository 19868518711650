import React, { useState, useEffect } from 'react';
import { ToolTip } from '../../../shared_elements';
import { Grid } from '@material-ui/core';
import AutomatedActionBtn from './AutomatedActionBtn';
import {
  globalGetService,
  globalPutService,
} from '../../../utils/globalApiServices';
import { toastFlashMessage } from '../../../utils';
import { Box } from 'portal-commons';

export default function DefaultAction(props) {
  const [action, setAction] = useState('REVIEW');
  const [loader, setLoader] = useState(true);
  const [enableSaveBtn, setEnableSaveBtn] = useState(false);
  const [actionLoader, setActionLoader] = useState(false);
  const [defaultActionObj, setDefaultActionObj] = useState({});

  useEffect(() => {
    globalGetService(`dca/primaryDcaCspRule`, { isDefaultRule: true }).then(
      (response) => {
        setLoader(false);
        if (response.status >= 200 && response.status < 300) {
          const defaultActionObj = response.data.records.find(
            (item) => item.cspUid == null
          );
          setDefaultActionObj(defaultActionObj ? defaultActionObj : {});
          setAction(defaultActionObj ? defaultActionObj.action : 'REVIEW');
        }
      }
    );
  }, []);
  const updateDefaultAutomatedAction = (data = {}) => {
    setActionLoader(true);
    globalPutService(`dca/primaryDcaCspRule?action=${action}`, {}).then(
      (response) => {
        setActionLoader(false);
        if (response.status >= 200 && response.status < 300) {
          toastFlashMessage('Automated action updated successfully', 'success');
          setDefaultActionObj(response.data);
          setAction(response.data.action);
          setEnableSaveBtn(false);
        }
      }
    );
  };
  const handleSelectAction = (option) => {
    if (option != defaultActionObj.action) {
      setEnableSaveBtn(true);
    } else {
      setEnableSaveBtn(false);
    }
    setAction(option);
  };
  return (
    <div className="default-action-wrapper" data-testid="defaultAction">
      <Grid container alignItems="center" className="default-action">
        <Grid item xs={8}>
          <p className="supportText">
            <Box flexDirection="row" alignItems="center">
              <span>DEFAULT ACTION FOR ALL DOWNSTREAM CNPs</span>
              <ToolTip
                title={`Please select the action you wish to be automatically applied to all incoming Campaigns by all CSPs. Individual exceptions can be set for selected CSPs in the section below. The effect is limited to Campaigns for which you have been elected as a "Primary DCA". If the default action is set to "Review" you will have to manually Accept or Decline every submitted campaign.`}
              />
            </Box>
          </p>
        </Grid>
        <Grid item xs={4}>
          {loader ? (
            ''
          ) : (
            <ul className="select-action-wrapper list-inline">
              <li>
                <AutomatedActionBtn
                  action={action}
                  handleChange={handleSelectAction}
                  role={props.role}
                />
              </li>
              <li>
                <a
                  className={`primary-btn ${
                    (actionLoader ||
                      !enableSaveBtn ||
                      props.role == 'ROLE_REVIEWER') &&
                    defaultActionObj.action != undefined
                      ? 'disabled'
                      : ''
                  }`}
                  onClick={updateDefaultAutomatedAction}
                >
                  Save
                </a>
              </li>
            </ul>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
