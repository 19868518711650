export const DEV_BASE_URL = process.env.REACT_APP_BASE_URL
  ? process.env.REACT_APP_BASE_URL
  : 'https://dca-portal-api-dev.campaignregistry.com/api/v1/';
export const OKTA_BASE_URL = process.env.REACT_APP_OKTA_BASE
  ? process.env.REACT_APP_OKTA_BASE
  : 'https://dev-752242.okta.com/';
export const S3_ASSETS_PATH = process.env.REACT_APP_S3_ASSETS_PATH
  ? process.env.REACT_APP_S3_ASSETS_PATH
  : 'http://static-dev.campaignregistry.com/dca';
export const OKTA_ISSUER_URL = process.env.REACT_APP_OKTA_AUTH_SERVER
  ? process.env.REACT_APP_OKTA_AUTH_SERVER
  : 'https://dev-752242.okta.com/oauth2/aus55c9gjusrxsQUS357';
export const OKTA_CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID
  ? process.env.REACT_APP_OKTA_CLIENT_ID
  : '0oa55ce4j53I8eL4M357';
export const GOOGLE_RECAPTCHA_SITE_KEY = process.env
  .REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY
  ? process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY
  : '6LcwRCYaAAAAAJcrI20yKbz9O4Zgd6S_ErRrt6tX';
export const DATADOG_APPLICATION_ID = process.env
  .REACT_APP_DATADOG_APPLICATION_ID
  ? process.env.REACT_APP_DATADOG_APPLICATION_ID
  : '248ca596-8ca1-4fce-9ac6-c783457e2cef';
export const DATADOG_CLIENT_TOKEN = process.env.REACT_APP_DATADOG_CLIENT_TOKEN
  ? process.env.REACT_APP_DATADOG_CLIENT_TOKEN
  : 'pubb17917fa170f58724e591ad85f8bfa2e';
export const DATADOG_ENV = process.env.REACT_APP_DATADOG_ENV
  ? process.env.REACT_APP_DATADOG_ENV
  : 'local';
// eslint-disable-next-line @typescript-eslint/no-var-requires
export const PACKAGE_JSON_VERSION = require('../../package.json').version;

export const APP_ROLES = {
  GENERAL: [
    'DASHBOARD',
    '404',
    'VIEW_CAMPAIGNS_BY_CSPS',
    'VIEW_CAMPAIGNS_BY_PRIMARY_DCAS',
    'VIEW_CAMPAIGN_DETAILS',
    'VIEW_DCA_RULES',
    'VIEW_EVENTS',
  ],
  ROLE_REVIEWER: [],
  ROLE_USER: [
    'EDIT_CAMPAIGN',
    'EDIT_DCA_RULES',
    'API_KEY_INTEGRATION',
    'USERS_MANAGEMENT',
  ],
  ROLE_MANAGER: [
    'EDIT_CAMPAIGN',
    'EDIT_DCA_RULES',
    'API_KEY_INTEGRATION',
    'USERS_MANAGEMENT',
  ],
};

export const mnoNameOrder = [
  'AT&T',
  'T-Mobile',
  'Verizon Wireless',
  'US Cellular',
  'ClearSky',
  'Interop',
];
