import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  faTrash,
  faArrowRotateRight,
  faDna,
  faPen,
  faCopy,
  faEye,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, TableCell, TableRow } from '@material-ui/core';

import { ApiKeyActionDialog, AddNewApiKeyDialog } from './index';
import { CustomSwitch, CustomTooltip } from '../../../shared_elements';
import { toastFlashMessage } from '../../../utils';
import { convertTimeWithTimezone } from '../../../utils/time';
import { globalPutService } from '../../../utils/globalApiServices';

export default function ApiKeyListingRow(props) {
  const { accountObj } = props;
  const [account, setAccount] = useState(accountObj ? accountObj : {});
  const [editApiKey, setEditApiKey] = useState(false);
  const [cloneApiKey, setCloneApiKey] = useState(false);
  const [rotateApiKey, setRotateApiKey] = useState(false);
  const [deleteApiKey, setDeleteApiKey] = useState(false);
  const [loader, setLoader] = useState(false);
  // const [openTooltip, setOpenTooltip] = useState(false)
  // const [openSecretTooltip, setOpenSecretTooltip] = useState(false)

  const updateStatus = (status) => {
    const formdata = {
      enabled: status,
    };
    setLoader(true);
    globalPutService(`dca/api/account/${account.id}`, formdata).then(
      (response) => {
        setLoader(false);
        if (response.status >= 200 && response.status < 300) {
          // props.fetchApiAccounts()
          toastFlashMessage(`API Key updated successfully`, 'success');
          setAccount(response.data);
        }
      }
    );
  };

  useEffect(() => {
    setAccount(accountObj);
  }, [accountObj]);
  return (
    <>
      <TableRow data-testid="apiKeyListingRow">
        <TableCell data-testid="apiKeyListingRowDisplayName">
          {account.displayName || '--'}
        </TableCell>
        <TableCell>
          <div className="api-key-wrapper">
            <span data-testid="apiKeyListingRowApiKey">
              <CustomTooltip
                arrow
                title={
                  <div className="tooltip-content">
                    <p>
                      API KEY <br />
                      {account.apiKey}
                    </p>
                  </div>
                }
                placement="top"
              >
                <div className="img-wrapper">
                  <FontAwesomeIcon icon={faEye} size="lg" />
                </div>
              </CustomTooltip>
            </span>
            <span data-testid="apiKeyListingRowApiKeyCopyToClipboard">
              <CopyToClipboard
                text={account.apiKey}
                onCopy={() =>
                  toastFlashMessage(
                    'API Key has been copied to your clipboard',
                    'success'
                  )
                }
              >
                <CustomTooltip
                  arrow
                  placement="top"
                  title={<div className="tooltip-content">Copy</div>}
                >
                  <div className="img-wrapper copy">
                    <FontAwesomeIcon icon={faCopy} size="lg" />
                  </div>
                </CustomTooltip>
              </CopyToClipboard>
            </span>
          </div>
        </TableCell>
        <TableCell>
          <div className="secret-wrapper">
            <span data-testid="apiKeyListingRowSecret">
              <CustomTooltip
                arrow
                title={
                  <div className="tooltip-content">
                    <p>
                      SECRET <br />
                      {account.secret}
                    </p>
                  </div>
                }
                placement="top"
              >
                <div className="img-wrapper">
                  <FontAwesomeIcon icon={faEye} size="lg" />
                </div>
              </CustomTooltip>
            </span>
            <span data-testid="apiKeyListingRowSecretCopyButton">
              <CopyToClipboard
                text={account.secret}
                onCopy={() =>
                  toastFlashMessage(
                    'Secret has been copied to your clipboard',
                    'success'
                  )
                }
              >
                <CustomTooltip
                  arrow
                  placement="top"
                  title={<div className="tooltip-content">Copy</div>}
                >
                  <div className="img-wrapper copy">
                    <FontAwesomeIcon icon={faCopy} size="lg" />
                  </div>
                </CustomTooltip>
              </CopyToClipboard>
            </span>
          </div>
        </TableCell>
        <TableCell data-testid="apiKeyListingRowUpdateDate">
          {convertTimeWithTimezone(new Date(account.updateDate))}
        </TableCell>
        <TableCell data-testid="apiKeyListingRowCustomSwitch">
          <CustomSwitch
            val={account.enabled ? true : false}
            handleChange={() => updateStatus(!account.enabled)}
          />
        </TableCell>
        <TableCell>
          <div className="actions-wrapper">
            <span data-testid="apiKeyListingRowEdit">
              <CustomTooltip
                arrow
                title={<div className="tooltip-content">Edit</div>}
                placement="top"
              >
                <div
                  className="img-wrapper"
                  onClick={() => setEditApiKey(true)}
                >
                  <FontAwesomeIcon icon={faPen} size="lg" />
                </div>
              </CustomTooltip>
            </span>
            <span data-testid="apiKeyListingRowClone">
              <CustomTooltip
                arrow
                title={<div className="tooltip-content">Clone</div>}
                placement="top"
              >
                <div
                  className="img-wrapper clone"
                  onClick={() => setCloneApiKey(true)}
                >
                  <FontAwesomeIcon icon={faDna} size="lg" />
                </div>
              </CustomTooltip>
            </span>
            <span data-testid="apiKeyListingRowRotate">
              <CustomTooltip
                arrow
                title={<div className="tooltip-content">Rotate</div>}
                placement="top"
              >
                <div
                  className="img-wrapper"
                  onClick={() => setRotateApiKey(true)}
                >
                  <FontAwesomeIcon icon={faArrowRotateRight} size="lg" />
                </div>
              </CustomTooltip>
            </span>
            <span data-testid="apiKeyListingRowDelete">
              <CustomTooltip
                arrow
                title={<div className="tooltip-content">Delete</div>}
                placement="top"
              >
                <div
                  className="img-wrapper delete"
                  onClick={() => setDeleteApiKey(true)}
                >
                  <FontAwesomeIcon icon={faTrash} size="lg" />
                </div>
              </CustomTooltip>
            </span>
          </div>
        </TableCell>
        {cloneApiKey || rotateApiKey || deleteApiKey ? (
          <ApiKeyActionDialog
            type={
              cloneApiKey
                ? 'clone'
                : rotateApiKey
                ? 'rotate'
                : deleteApiKey
                ? 'delete'
                : ''
            }
            handleClose={() => {
              setCloneApiKey(false);
              setRotateApiKey(false);
              setDeleteApiKey(false);
            }}
            account={account}
            fetchApiAccounts={props.fetchApiAccounts}
          />
        ) : null}
        <AddNewApiKeyDialog
          open={editApiKey}
          type={'edit'}
          handleClose={() => setEditApiKey(false)}
          account={account}
          setAccount={setAccount}
        />
      </TableRow>
    </>
  );
}
