import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  makeStyles,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from '@material-ui/core';

import { useState, useEffect, useCallback } from 'react';
import { isValidInput } from '../../../utils';
import { getSuspensionCategories } from '../apis';

import { Button, Flex, BoxV2 as Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareMinus } from '@fortawesome/pro-regular-svg-icons';

const useStyles = makeStyles({
  container: {
    maxWidth: 600,
  },
  content: {
    width: '100%',
    padding: '24px 32px !important',
    borderRadius: 4,
    boxSizing: 'border-box',
  },
});

const CampaignSuspendDialog = ({
  disabled,
  profile,
  campaign,
  carrier,
  onClose,
  onSubmit,
}) => {
  const classes = useStyles();
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [suspensionCategory, setSuspensionCategory] = useState();
  const [explanation, setExplanation] = useState('');
  const [explanationError, setExplanationError] = useState('');
  const isSoleProprietor = campaign.usecase === 'SOLE_PROPRIETOR';
  const isRedacted = isSoleProprietor && !profile.dpaSigned;

  const selectMenuProps = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  };

  const validate = () => {
    if (explanation.length < 1) {
      setExplanationError('Please enter explanation');
      return false;
    } else if (explanation.length > 3000) {
      setExplanationError('Maximum 3000 chars are allowed');
      return false;
    } else if (!isValidInput(explanation)) {
      setExplanationError('Invalid input');
      return false;
    }
    return true;
  };

  const handleExplanationChange = (event) => {
    setExplanation(event.target.value);
    setExplanationError('');
  };

  const handleExplanationBlur = () => {
    validate();
  };

  const handleSubmit = () => {
    const valid = validate();
    if (valid) {
      onSubmit && onSubmit(explanation, suspensionCategory);
    }
  };

  const getAllSuspensionCategories = useCallback(async () => {
    const categories = await getSuspensionCategories();
    const options = categories.map((category) => ({
      label: category.name,
      value: category.id,
    }));
    setCategoryOptions(options);
  }, []);

  useEffect(() => {
    getAllSuspensionCategories();
  }, []);

  return (
    <Dialog
      className="default-dialog"
      classes={{ paper: classes.container }}
      open
      disableBackdropClick
      fullWidth
      maxWidth="xs"
      aria-labelledby="responsive-dialog-title"
      onClose={onClose}
    >
      <DialogContent classes={{ root: classes.content }}>
        <Flex
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            color: 't.black80',
            py: 12,
          }}
        >
          <FontAwesomeIcon
            icon={faSquareMinus}
            size="lg"
            style={{ marginRight: 8, height: 20 }}
          />
          <Box
            sx={{
              fontSize: 'H600',
              fontWeight: 600,
              lineHeight: 'normal',
            }}
          >
            Suspend Campaign
          </Box>
        </Flex>
        <Box
          sx={{
            fontSize: 'H100',
            lineHeight: 'H200',
            mb: 8,
            fontWeight: 400,
            color: 't.black80',
          }}
        >
          * Indicates a Required Field
        </Box>
        <div className="modal-content">
          <div className="form-group-field">
            <TextField
              InputLabelProps={{ shrink: true }}
              disabled
              fullWidth
              label="Campaign ID"
              value={campaign ? campaign.uid : 'N/A'}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 10,
            }}
            className="form-group-field"
          >
            <TextField
              InputLabelProps={{ shrink: true }}
              style={{ flex: 1 }}
              disabled
              fullWidth
              label="Brand ID"
              value={campaign ? campaign.brand.uid : 'N/A'}
            />
            <TextField
              InputLabelProps={{ shrink: true }}
              style={{ flex: 4 }}
              disabled
              fullWidth
              label="Brand Name"
              value={
                isRedacted
                  ? 'Redacted due to PII'
                  : campaign.brand.displayName || 'N/A'
              }
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 10,
            }}
            className="form-group-field"
          >
            <TextField
              InputLabelProps={{ shrink: true }}
              style={{ flex: 1 }}
              disabled
              fullWidth
              label="CSP ID"
              value={campaign ? campaign.brand.csp.uid : 'N/A'}
            />
            <TextField
              InputLabelProps={{ shrink: true }}
              style={{ flex: 4 }}
              disabled
              fullWidth
              label="CSP Name"
              value={campaign ? campaign.brand.csp.displayName : 'N/A'}
            />
          </div>
          <div className="form-group-field">
            <TextField
              InputLabelProps={{ shrink: true }}
              disabled
              fullWidth
              label="Carrier"
              value={
                carrier && carrier.rulesEngineResultsJson
                  ? carrier.rulesEngineResultsJson.mno || '--'
                  : '--'
              }
            />
          </div>
          <div className="form-group-field">
            <FormControl
              style={{ width: '100%' }}
              data-testid="suspensionCategoryField"
            >
              <InputLabel>Campaign Suspension Category</InputLabel>
              <Select
                MenuProps={selectMenuProps}
                value={suspensionCategory}
                fullWidth={true}
                inputProps={{
                  'aria-label': 'suspensionCategory',
                  'data-testid': 'suspensionCategorySelect',
                }}
                onChange={(event) => setSuspensionCategory(event.target.value)}
              >
                {categoryOptions.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <div className="form-group-field">
            <TextField
              InputLabelProps={{ shrink: true }}
              required
              fullWidth
              multiline
              rowsMax={4}
              error={!!explanationError}
              label="Explanation"
              value={explanation}
              onChange={handleExplanationChange}
              onBlur={handleExplanationBlur}
            />
            {explanationError ? (
              <h6 className="error-msg">{explanationError}</h6>
            ) : null}
          </div>
          <Flex
            sx={{
              alignContent: 'center',
              justifyContent: 'center',
              gap: 20,
              mt: 24,
            }}
          >
            <Button variant="outline" disabled={disabled} onClick={onClose}>
              Cancel
            </Button>
            <Button
              disabled={disabled || !!explanationError}
              onClick={handleSubmit}
            >
              Suspend
            </Button>
          </Flex>
        </div>
      </DialogContent>
    </Dialog>
  );
};

CampaignSuspendDialog.propTypes = {
  disabled: PropTypes.bool,
  profile: PropTypes.shape({
    dpaSigned: PropTypes.bool.isRequired,
  }),
  campaign: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    brand: PropTypes.shape({
      uid: PropTypes.string.isRequired,
      displayName: PropTypes.string,
      csp: PropTypes.shape({
        uid: PropTypes.string.isRequired,
        displayName: PropTypes.string,
      }).isRequired,
    }).isRequired,
    usecase: PropTypes.string.isRequired,
  }),
  carrier: PropTypes.shape({
    rulesEngineResultsJson: PropTypes.string,
  }),
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default CampaignSuspendDialog;
