import React from 'react';
import { useStylesTable } from '../utils/tableUIStyle';
import { withStyles } from '@material-ui/core/styles';
import {
  Icon,
  Table,
  TableBody,
  TableCell,
  Checkbox,
  TableRow,
  Paper,
  TableHead,
  TableSortLabel,
  LinearProgress,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Pagination from '@material-ui/lab/Pagination';
import { S3_ASSETS_PATH } from '../constants';

const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: '#fff',
  },
  barColorPrimary: {
    background: '#00AC9D',
  },
})(LinearProgress);

function CustomTable(props) {
  const classes = useStylesTable();
  const {
    headRows,
    filter,
    createSortHandler,
    tableData,
    pagination = {},
    dataAttributes = {},
  } = props;
  const emptyRows = Object.keys(pagination).length
    ? pagination.rowsPerPage -
      Math.min(pagination.rowsPerPage, pagination.totalRecords)
    : null;

  return (
    <Paper className={classes.paper} {...dataAttributes}>
      <div className={`${classes.tableWrapper} tableWrapper`}>
        <Table
          className={`${classes.table}`}
          aria-labelledby="tableTitle"
          size="medium"
        >
          <TableHead>
            <TableRow>
              {props.reviewPage ? (
                <td className="custom-tablecell">
                  <Checkbox
                    // indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={props.isAllSelected() ? true : false}
                    onChange={props.handleSelectAllCampaign}
                    inputProps={{ 'aria-label': 'select all campaigns' }}
                    icon={
                      <Icon classes={{ root: classes.iconRoot }}>
                        <img
                          className={classes.imageIcon}
                          src={`${S3_ASSETS_PATH}/images/MNO/unchecked-new-bulk.svg`}
                        />
                      </Icon>
                    }
                    checkedIcon={
                      <Icon classes={{ root: classes.iconRoot }}>
                        <img
                          className={classes.checkedImageIcon}
                          src={`${S3_ASSETS_PATH}/images/MNO/checked-new-bulk.svg`}
                        />
                      </Icon>
                    }
                  />
                </td>
              ) : null}
              {headRows.map((row) => (
                <TableCell
                  data-testid={row.label}
                  key={row.id}
                  align={'left'}
                  padding={'default'}
                  sortDirection={
                    filter
                      ? filter.sortField === row.id
                        ? filter.ascendingOrder == true ||
                          filter.ascendingOrder == 'true'
                          ? 'asc'
                          : 'desc'
                        : false
                      : null
                  }
                >
                  {row.sortOption ? (
                    <TableSortLabel
                      active={filter.sortField === row.id}
                      direction={
                        filter.ascendingOrder == true ||
                        filter.ascendingOrder == 'true'
                          ? 'asc'
                          : 'desc'
                      }
                      onClick={() => createSortHandler(row.id)}
                      //    IconComponent={<img src={`${S3_ASSETS_PATH}/images/sort-icon.svg`} />}
                    >
                      {' '}
                      {row.label}{' '}
                    </TableSortLabel>
                  ) : (
                    <span> {row.label} </span>
                  )}
                </TableCell>
              ))}
            </TableRow>
            {props.tableLoader ? (
              <TableRow className="empty-row loader">
                <TableCell
                  colSpan={props.reviewPage ? 9 : 8}
                  style={{ height: 0, padding: 0, borderBottom: 'none' }}
                >
                  <ColorLinearProgress className={classes.margin} />
                </TableCell>
              </TableRow>
            ) : null}
          </TableHead>
          <TableBody>
            {tableData}
            {emptyRows > 0 && !props.tableLoader && props.records.total == 0 ? (
              <TableRow
                style={{ height: 48 * emptyRows }}
                className="empty-row no-data-found"
              >
                {
                  <TableCell
                    colSpan={props.reviewPage ? 9 : 8}
                    className="no-records-found"
                    style={
                      props.records.total != 0 ? { background: '#fff' } : {}
                    }
                  >
                    <img
                      src={`${S3_ASSETS_PATH}/images/no-data-found.svg`}
                      alt=""
                    />
                    <p className="paragraph">
                      No data,
                      <br /> <span>{props.emptyState}</span>
                    </p>
                  </TableCell>
                }
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </div>
      {Object.keys(pagination).length &&
      pagination.totalRecords &&
      pagination.count > 1 ? (
        <div className="pagination-wrapper">
          <Pagination
            variant="outlined"
            shape="rounded"
            className="pagination"
            count={pagination.count}
            page={pagination.page}
            onChange={props.handleChangePage}
          />
        </div>
      ) : null}
    </Paper>
  );
}
CustomTable.propTypes = {
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  createSortHandler: PropTypes.func,
  filter: PropTypes.object,
  headRows: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
  pagination: PropTypes.object,
};
export default CustomTable;
