import React, { useEffect, useState } from 'react';
import { Container, Grid } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { DashboardBlk, SearchTextField } from '../components';
import { toastFlashMessage } from '../../../utils';
import { fieldValidation } from '../../../utils/validator';
import '../../../assets/styles/dashboard-module.scss';
import { getCampaignDetail, getCampaignByPhone } from '../apiServices';

export default function Dashboard(props) {
  const history = useHistory();
  const location = useLocation();
  const errorCode = {
    id: {
      0: '',
      2: 'Minimum 7 characters id',
      3: 'Maximum 7 characters id',
      4: 'Invalid id',
    },
    idObj: {
      regexPattern: /^[a-zA-Z0-9]{7}$/,
      maxLength: 7,
      minLength: 7,
    },
    phone: {
      0: '',
      2: 'Minimum 10 digits phone number',
      4: 'Invalid phone number',
    },
    phoneObj: {
      // refer to https://campaignreg.atlassian.net/wiki/x/CICuC
      regexPattern: /^\+?(?:[0-9][_!#$%&'*+\/=?`{|}~^.\-()\s]*?){6,14}[0-9]$/,
      minLength: 10,
    },
  };
  const [campaignSearchValue, setCampaignSearchValue] = useState('');
  const [campaignSearchError, setCampaignSearchError] = useState(false);
  const [campaignSearching, setCampaignSearching] = useState(false);

  const isIdPattern = (value) => {
    return /^(?=.*[a-zA-Z])[a-zA-Z0-9]*$/.test(value);
  };

  const validator = (allowEmpty = true) => {
    if (!allowEmpty && !campaignSearchValue) {
      return 'This field is required';
    }
    let errorMsg = '';
    if (isIdPattern(campaignSearchValue)) {
      errorMsg =
        errorCode.id[
          fieldValidation({
            ...errorCode.idObj,
            fieldval: campaignSearchValue,
          })
        ];
    } else {
      errorMsg =
        errorCode.phone[
          fieldValidation({
            ...errorCode.phoneObj,
            fieldval: campaignSearchValue,
          })
        ];
    }
    return errorMsg;
  };

  const handleError = () => {
    const errorMsg = validator();
    setCampaignSearchError(errorMsg);
  };

  const handleSearchChange = (value) => {
    setCampaignSearchValue(value);
    setCampaignSearchError('');
  };

  const handleSearchClick = async () => {
    setCampaignSearching(true);
    const errorMsg = validator(false);
    if (!errorMsg) {
      let response = null;
      if (isIdPattern(campaignSearchValue)) {
        const promise1 = getCampaignDetail(campaignSearchValue, {
          provisional: false,
        });
        const promise2 = getCampaignDetail(campaignSearchValue, {
          provisional: true,
        });
        const [result1, result2] = await Promise.all([promise1, promise2]);
        response = result1 || result2;
      } else {
        response = await getCampaignByPhone(campaignSearchValue);
      }
      setCampaignSearching(false);
      if (response) {
        history.push({
          pathname: `/campaigns/${response.uid}`,
          state: { goBackPage: location.pathname },
        });
      } else {
        toastFlashMessage('Campaign not found', 'error');
      }
    } else {
      setCampaignSearchError(errorMsg);
      setCampaignSearching(false);
    }
  };

  useEffect(() => {
    const loginFlag = localStorage.getItem('loginSuccess');
    if (loginFlag === '1') {
      toastFlashMessage('Logged in successfully', 'success');
      localStorage.removeItem('loginSuccess');
    }
  }, []);
  return (
    <section className="dashboard-section" data-testid="dashboard">
      <Container maxWidth={false} className="dashboard-container">
        <Grid container spacing={3} direction="row-reverse">
          <Grid item xs={5} justifyContent="flex-end">
            <SearchTextField
              label="Search by Campaign ID or Phone Number"
              value={campaignSearchValue}
              error={campaignSearchError}
              searching={campaignSearching}
              handleChange={handleSearchChange}
              handleError={handleError}
              handleSearchClick={handleSearchClick}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <DashboardBlk title="Shared by CSPs" type="primaryDca" />
          </Grid>
          <Grid item xs={12}>
            <DashboardBlk title="Shared by Primary DCAs" />
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}
