// src/App.js
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { Toast, ThemeProvider, dcaTheme } from 'portal-commons';
import configureStore from './store/configureStore';
import Favicon from 'react-favicon';
import './assets/styles/_app.scss';
import { S3_ASSETS_PATH } from './constants';
import AppWithRouterAccess from './AppWithRouterAccess';
import datadog from './utils/datadog';
import LoadingModal from './shared_elements/LoadingModal';

const store = configureStore();

datadog.init();
const userJsonString = localStorage.getItem('user');
if (userJsonString) {
  const user = JSON.parse(userJsonString);
  !datadog.hasUser() && datadog.setUser(user);
}

const App = () => {
  return (
    <div className="App" data-testid="app">
      <ThemeProvider theme={dcaTheme}>
        <Favicon url={`${S3_ASSETS_PATH}/images/favicon-48x48.ico`} />
        <Provider store={store}>
          <LoadingModal />
          <Toast />
          <Router>
            <AppWithRouterAccess />
          </Router>
        </Provider>
      </ThemeProvider>
    </div>
  );
};
export default App;
