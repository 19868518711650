import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { TextField } from '@material-ui/core';
import { fieldValidation } from '../../../utils/validator';
import regexExpressions from '../../../constants/regExpressions';
import { S3_ASSETS_PATH, GOOGLE_RECAPTCHA_SITE_KEY } from '../../../constants';
import { toastFlashMessage } from '../../../utils';
import { forgotPasswordApi } from '../apiServices';
import ReCAPTCHA from 'react-google-recaptcha';

export default withRouter(
  class ForgotPasswordForm extends Component {
    constructor(props) {
      super(props);
      this.state = {
        callback: 'not fired',
        grecaptchaResponse: '',
        captchaValueNotSet: true,
        load: false,
        expired: 'false',
        userInfo: {
          email: '',
        },
        error: {},
        errorCode: {
          email: {
            0: '',
            1: 'Please enter Email Address',
            4: 'Invalid Email Address',
          },
          emailObj: {
            requiredFlag: true,
            regexPattern: regexExpressions.email,
          },
        },
        loader: false,
      };
      this._reCaptchaRef = React.createRef();
    }
    handleChange = (value, key) => {
      this.setState((prevState) => ({
        ...prevState,
        userInfo: {
          ...prevState.userInfo,
          [key]: value,
        },
        error: {
          ...prevState.error,
          [key]: '',
        },
      }));
    };
    handleError = (value, key) => {
      const { errorCode } = this.state;
      this.setState((prevState) => ({
        ...prevState,
        error: {
          ...prevState.error,
          [key]:
            errorCode[key][
              fieldValidation({ ...errorCode[key + 'Obj'], fieldval: value })
            ],
        },
      }));
    };
    forgotPassword = (e) => {
      e.preventDefault();
      const { errorCode, userInfo, grecaptchaResponse } = this.state;
      this.setState({
        loader: true,
      });
      const validateNewInput = {
        email:
          errorCode['email'][
            fieldValidation({
              ...errorCode['emailObj'],
              fieldval: userInfo.email,
            })
          ],
      };
      if (
        Object.keys(validateNewInput).every((k) => {
          return validateNewInput[k] === '';
        }) &&
        grecaptchaResponse
      ) {
        forgotPasswordApi({
          email: userInfo.email.trim(),
          grecaptchaResponse,
        }).then((response) => {
          this.setState({ loader: false });
          if (response.status >= 200 && response.status < 300) {
            toastFlashMessage('Email has been sent', 'success');
            this.props.history.push('user/reset/successfully');
          }
        });
      } else {
        this.setState({
          error: validateNewInput,
          loader: false,
        });
      }
    };

    componentDidMount() {
      this.setState({ load: true });
    }

    handleChangeCapcha = (value) => {
      this.setState({ grecaptchaResponse: value, captchaValueNotSet: false });
      if (value === null) this.setState({ expired: true });
    };

    asyncScriptOnLoad = () => {
      this.setState({ load: true });
    };

    handleOnExpired = () => {
      this.setState({
        grecaptchaResponse: null,
        captchaValueNotSet: true,
      });
    };

    handleNetworkError = (err) => {
      this.setState({ grecaptchaResponse: null, captchaValueNotSet: null });
      toastFlashMessage(
        'Network error, check your internet connection!',
        'error'
      );
    };
    render() {
      const { userInfo, error, loader, captchaValueNotSet } = this.state;
      return (
        <div
          className="auth-user-form forgot-password"
          data-testid="forgotPassword"
        >
          <form onSubmit={this.forgotPassword}>
            <div className="form-title">
              <h3 className="heading2">
                <img src={`${S3_ASSETS_PATH}/images/forgot-pswd.svg`} alt="" />
                <span>
                  {this.props.location.pathname == '/expired-password'
                    ? 'Password expired ?'
                    : 'Forgot your password?'}
                </span>
              </h3>
              <p className="paragraph">
                Enter the email address you used to sign up on{' '}
                <span>campaignregistry.com</span>. We’ll send you a link to
                reset your password.
              </p>
            </div>
            <ul className="list-unstyled">
              <li>
                <div className="form-group-field">
                  <TextField
                    error={error.email ? true : false}
                    id="email"
                    data-testid="forgotPasswordEmailInput"
                    label="Email"
                    autoComplete="off"
                    value={userInfo.email}
                    required={true}
                    fullWidth={true}
                    inputProps={{ 'aria-label': 'email' }}
                    onChange={(event) =>
                      this.handleChange(event.target.value, 'email')
                    }
                    onBlur={(event) =>
                      this.handleError(event.target.value, 'email')
                    }
                  />
                  {error.email ? (
                    <h6 className="error-msg">{error.email}</h6>
                  ) : null}
                </div>
              </li>
              <li style={{ textAlign: 'center' }}>
                {
                  <ReCAPTCHA
                    style={{ display: 'inline-block' }}
                    theme="light"
                    ref={this._reCaptchaRef}
                    sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
                    onChange={this.handleChangeCapcha}
                    onExpired={this.handleOnExpired}
                    asyncScriptOnLoad={this.asyncScriptOnLoad}
                    onErrored={this.handleNetworkError}
                  />
                }
              </li>
              <li>
                <div
                  className="form-group-field"
                  style={{ textAlign: 'center' }}
                >
                  <input
                    type="submit"
                    data-testid="forgotPasswordSubmitButton"
                    disabled={
                      loader ||
                      Object.keys(error).find((k) => error[k] != '') ||
                      captchaValueNotSet
                    }
                    value={'Submit'}
                  />
                </div>
              </li>
            </ul>
            <p
              className="text-center account-link-option"
              data-testid="forgotPasswordBackButton"
            >
              Back to <Link to="/login">Login</Link>
            </p>
          </form>
        </div>
      );
    }
  }
);
