import apiInstance from '../../utils/apiInterceptor';

export async function getCampaignDetail(campaignUid, params = {}) {
  const response = await apiInstance.get(`dca/campaigns/${campaignUid}`, {
    params,
    validateStatus: null,
  });
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
  return null;
}

export async function getCampaignByPhone(phoneNumber) {
  const response = await apiInstance.get(
    `dca/campaigns/byPhone/${phoneNumber}`,
    { validateStatus: null }
  );
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
  return null;
}
