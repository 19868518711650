import React from 'react';
import { Tooltip } from '@material-ui/core';
import { S3_ASSETS_PATH } from '../constants';
import { withStyles } from '@material-ui/core/styles';
import { Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/pro-regular-svg-icons';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    background: '#0091B3',
    color: '#fff',
    fontSize: 12,
    borderRadius: '20px 20px 20px 0px',
    maxWidth: 220,
    fontFamily: 'Roboto',
    padding: '8px 20px',
    marginTop: 40,
    fontWeight: 400,
  },
}))(Tooltip);
const ToolTip = (props) => {
  return (
    <LightTooltip title={props.title} placement="right-end">
      <div style={{ cursor: 'pointer' }}>
        <Box margin={{ left: 'xs', bottom: 'xxxs' }}>
          <FontAwesomeIcon
            icon={faInfo}
            style={{ color: '#00AC9D' }}
            size={props.size ? props.size : undefined}
          />
        </Box>
      </div>
    </LightTooltip>
  );
};
export default ToolTip;
