import { ErrorBoundaryHoc, PageLayoutHoc, RequiredAuth } from '../../hocs';
import UsersProfile from './containers/UsersProfile';

export const usersRoutes = [
  {
    path: '/profile',
    component: RequiredAuth(
      PageLayoutHoc(ErrorBoundaryHoc(UsersProfile), {
        title: 'USERS',
        id: 'USERS_MANAGEMENT',
      })
    ),
  },
];
