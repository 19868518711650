import React, { useState } from 'react';
import { Menu, MenuItem, Fade } from '@material-ui/core';

import { Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';

export default function AutomatedActionBtn(props) {
  const { action, exception, role } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
  };
  return (
    <>
      <div
        className={`paragraph select-action-btn ${action} ${
          role == 'ROLE_REVIEWER' ? 'disabled' : ''
        }`}
        data-testid="automatedActionButton"
        onClick={handleClick}
      >
        <Box
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <span>{action ? `${action.toLowerCase()}` : ''}</span>
          <FontAwesomeIcon
            icon={faAngleDown}
            style={{ color: 'white' }}
            className={`${Boolean(anchorEl) ? 'open' : ''}`}
            size="lg"
          />
        </Box>
      </div>
      <Menu
        id="simple-menu"
        data-testid="automatedActionMenu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className="default-actions-menu default-custom-menu"
        MenuListProps={
          {
            // onMouseLeave: handleRequestClose
          }
        }
        TransitionComponent={Fade}
      >
        {(exception
          ? ['ACCEPT', 'REVIEW', 'DECLINE']
          : ['REVIEW', 'DECLINE']
        ).map((item, index) =>
          action != item ? (
            <MenuItem
              key={index}
              data-testid={`automatedActionMenu-${item}`}
              onClick={() => {
                props.handleChange(item);
                setAnchorEl(null);
              }}
            >{`${item.toLowerCase()}`}</MenuItem>
          ) : null
        )}
      </Menu>
    </>
  );
}
