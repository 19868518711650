import { globalGetService } from '../../utils/globalApiServices';
import { getTimezone } from '../../utils/time';

export function fetchEventHistory(query = {}) {
  return globalGetService('dca/event', {
    ...query,
    timezone: getTimezone(),
  }).then((response) => {
    if (response && response.status >= 200 && response.status < 300) {
      return response.data;
    }
  });
}

export const downloadEventHistory = async (query = {}) => {
  const response = await globalGetService(
    'dca/event/csv',
    { ...query, timezone: getTimezone() },
    'arraybuffer'
  );
  if (response?.ok) {
    return response.data;
  }
};

export function getEventTypeOptions() {
  return globalGetService('enums/eventTypes').then((response) => {
    if (response?.ok) {
      const eventTypes = response.data?.map((event) => ({
        label: event.id,
        value: event.id,
      }));
      return eventTypes ?? [];
    }
    return [];
  });
}
