import EventsList from './containers/EventsList';
import { ErrorBoundaryHoc, PageLayoutHoc, RequiredAuth } from '../../hocs';

export const eventsRoutes = [
  {
    path: '/events',
    component: RequiredAuth(
      PageLayoutHoc(ErrorBoundaryHoc(EventsList), {
        title: 'EVENTS',
        id: 'VIEW_EVENTS',
      })
    ),
  },
];
