import { FunctionComponent } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TableCell, TableRow } from '@material-ui/core';
import { convertTimeWithTimezone } from '../../../utils/time';

interface CampaignsByCspsListingRowProps {
  cspName?: string;
  usecase?: string;
  campaignUid: string;
  brandUid?: string;
  sharedOnDate?: number;
}

const CampaignsByCspsListingRow: FunctionComponent<
  CampaignsByCspsListingRowProps
> = ({ cspName, usecase, campaignUid, brandUid, sharedOnDate }) => {
  const location = useLocation();
  const history = useHistory();
  return (
    <TableRow
      style={{ height: 40, cursor: 'pointer' }}
      onClick={() =>
        history.push({
          pathname: `/campaigns/${campaignUid}`,
          state: { goBackPage: location.pathname },
        })
      }
    >
      <TableCell data-testid="dashboardTableName">{cspName || '--'}</TableCell>
      <TableCell data-testid="dashboardTableUsecaseOrMno">
        {usecase || '--'}
      </TableCell>
      <TableCell data-testid="dashboardTableCampaignId">
        {campaignUid}
      </TableCell>
      <TableCell data-testid="dashboardTableBrandId">
        {brandUid || '--'}
      </TableCell>
      <TableCell data-testid="dashboardTableSharedDate">
        {sharedOnDate
          ? convertTimeWithTimezone(sharedOnDate, 'MM/DD/YYYY')
          : '-'}
      </TableCell>
    </TableRow>
  );
};

export default CampaignsByCspsListingRow;
