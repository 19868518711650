import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, Grid, TextField } from '@material-ui/core';

import { fieldValidation } from '../../../utils/validator';

const CampaignsByPrimaryDcaActionDialog = ({
  open,
  disabled,
  type,
  onClose,
  onSubmit,
}) => {
  const [explanation, setExplanation] = useState('');
  const [error, setError] = useState('');
  const campaignRejectionDescription = {
    0: '',
    1: 'Please enter Explanation',
    3: 'Maximum 2048 chars are allowed',
    5: 'Invalid input',
  };
  const campaignRejectionDescriptionObj = {
    requiredFlag: true,
    maxLength: 2048,
    xssProtection: true,
  };

  const handleSubmit = () => {
    if (type === 'accept') {
      onSubmit && onSubmit();
    } else {
      const updatedExplanation = explanation.trim();
      const checkError =
        campaignRejectionDescription[
          fieldValidation({
            ...campaignRejectionDescriptionObj,
            fieldval: updatedExplanation,
          })
        ];
      if (!!checkError) {
        setError(checkError);
        return;
      }
      onSubmit && onSubmit(updatedExplanation);
    }
  };

  useEffect(() => {
    if (open) {
      setExplanation('');
      setError('');
    }
  }, [open]);

  return (
    <Dialog
      className="default-dialog"
      disableBackdropClick
      fullWidth
      maxWidth="xs"
      aria-labelledby="responsive-dialog-title"
      open={open}
      onClose={onClose}
      data-testid="campaignsByPrimaryDcaActionDialog"
    >
      <DialogContent className="campaigns-by-primaryDcas-action-dialog">
        <div className="content-wrapper text-center">
          {type === 'accept' ? (
            <p className="paragraph">
              Are you sure you want to
              <br />
              <b>accept the campaign</b>?
            </p>
          ) : (
            <>
              <p className="paragraph">
                Are you sure you want to
                <br />
                <b>decline the campaign</b>?
                <br />
                <br />
                This action is irrevocable.
              </p>
              <div className="form-group-field" style={{ marginTop: 20 }}>
                <TextField
                  fullWidth
                  error={!!error}
                  value={explanation}
                  label="Explanation*"
                  data-testid="campaignsByPrimaryDcaActionDialogExplanationInput"
                  inputProps={{ 'aria-label': 'explanation' }}
                  onChange={(event) => {
                    setExplanation(event.target.value);
                    setError('');
                  }}
                  onBlur={(event) => {
                    setError('');
                    setExplanation(event.target.value);
                  }}
                />
                {error && <h6 className="error-msg">{error}</h6>}
              </div>
            </>
          )}
        </div>
        <ul className="list-inline footer text-center">
          <li>
            <p>
              <a
                className="secondary-btn"
                onClick={onClose}
                data-testid="campaignsByPrimaryDcaActionDialogCancelButton"
              >
                Cancel
              </a>
            </p>
          </li>
          <li>
            <p>
              <a
                className={`primary-btn ${
                  disabled || !!error ? 'disabled' : ''
                }`}
                onClick={handleSubmit}
                data-testid="campaignsByPrimaryDcaActionDialogProceedButton"
              >
                Proceed
              </a>
            </p>
          </li>
        </ul>
      </DialogContent>
    </Dialog>
  );
};

CampaignsByPrimaryDcaActionDialog.propTypes = {
  open: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default CampaignsByPrimaryDcaActionDialog;
