import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';

const colors = {
  borderColor: '#E56B29',
  labelColor: '#98471B',
  errorColor: '#AA0000',
};

const useStyles = makeStyles({
  textFieldWrapper: {
    width: 'fit-content',
    position: 'relative',
    marginBottom: 8,
    float: 'right',
    '& .MuiFormControl-root': {
      height: 40,
      width: 362,
      '&.Mui-error': {
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
          color: colors.errorColor,
          '& + .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.errorColor,
            borderWidth: 2,
          },
        },
      },
    },
    '& .MuiFormLabel-root': {
      fontSize: '14px',
      fontWeight: 400,
      paddingLeft: 20,
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 14px) scale(1)',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(0, -6px) scale(0.85)',
        color: colors.labelColor,
      },
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      '& + .MuiOutlinedInput-root': {
        backgroundColor: 'transparent',
        borderRadius: 8,
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: colors.borderColor,
          borderWidth: 2,
        },
      },
    },
    '& .MuiOutlinedInput-root': {
      height: '100%',
      borderRadius: 8,
      backgroundColor: '#E6E7E7',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
      '&.Mui-focused': {
        backgroundColor: 'transparent',
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: '8px 12px 8px 35px',
    },
    '& .MuiFormHelperText-root': {
      color: colors.errorColor,
      marginTop: 0,
      marginLeft: 10,
      fontSize: 10,
      fontWeight: 400,
      height: 16,
      position: 'absolute',
      bottom: -16,
      left: 0,
    },
  },
  '@keyframes spin': {
    '0%': { left: '12px', top: '14px' },
    '12.5%': { left: '12.6px', top: '13.6px' },
    '25%': { left: '14px', top: '12px' },
    '37.5%': { left: '15.6px', top: '12.6px' },
    '50%': { left: '16px', top: '14px' },
    '62.5%': { left: '15.6px', top: '15.6px' },
    '75%': { left: '14px', top: '16px' },
    '87.5%': { left: '12.6px', top: '15.6px' },
    '100%': { left: '12px', top: '14px' },
  },
  searchIcon: {
    position: 'absolute',
    zIndex: 1,
    fontSize: '14px',
    left: 12,
    top: 14,
    color: '#333E41',
    cursor: 'pointer',
    transition: 'left 2s linear, top 2s linear',
    '&.searching': {
      animation: '$spin 2s linear infinite',
    },
  },
});

interface Props {
  label: string;
  value: string;
  error: string;
  searching?: boolean;
  handleChange: (value: string) => void;
  handleError: () => void;
  handleSearchClick: () => void;
}

const SearchTextField: React.FC<Props> = ({
  label,
  value,
  error,
  searching,
  handleChange,
  handleError,
  handleSearchClick,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.textFieldWrapper}>
      <FontAwesomeIcon
        className={`${searching ? 'searching ' : ' '}${classes.searchIcon}`}
        icon={faMagnifyingGlass}
        onClick={handleSearchClick}
        data-testid="dashboardSearchCampaignIdFieldSearchIcon"
      />
      <TextField
        className={error.length > 0 ? 'Mui-error' : ''}
        variant="outlined"
        label={label}
        value={value}
        helperText={error}
        inputProps={{
          'aria-label': 'vettingClass',
          'data-testid': 'dashboardSearchCampaignId',
        }}
        onChange={(e) => handleChange(e.target.value.trim())}
        onBlur={handleError}
        onKeyPress={(e) => e.key === 'Enter' && handleSearchClick()}
      />
    </div>
  );
};

export default SearchTextField;
