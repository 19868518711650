import { Box, Table } from 'portal-commons';
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDiagramVenn,
  faBuildingCircleArrowRight,
} from '@fortawesome/pro-regular-svg-icons';
import { Grid } from '@material-ui/core';

import CampaignsByCspsListingRow from './CampaignsByCspsListingRow';
import CampaignsByPrimaryDcasListingRow from './CampaignsByPrimaryDcasListingRow';
import DashboardCount from './DashboardCount';
import { globalGetService } from '../../../utils/globalApiServices';

const byCspsHeadRows = [
  {
    id: 'cspName',
    label: 'CSP NAME',
    sortable: false,
  },
  {
    id: 'usecase',
    label: 'USECASE',
    sortable: false,
  },
  {
    id: 'uid',
    label: 'CAMPAIGN ID',
    sortable: false,
  },
  {
    id: 'brandUid',
    label: 'BRAND ID',
    sortable: false,
  },
  {
    id: 'sharedOnDate',
    label: 'SHARED WITH ME ON',
    sortable: false,
  },
];

const byPrimaryDcaHeadRows = [
  {
    id: 'primaryDcaName',
    label: 'PRIMARY DCA',
    sortable: false,
  },
  {
    id: 'mnoName',
    label: 'ELECTED FOR MNO',
    sortable: false,
  },
  {
    id: 'uid',
    label: 'CAMPAIGN ID',
    sortable: false,
  },
  {
    id: 'brandUid',
    label: 'BRAND ID',
    sortable: false,
  },
  {
    id: 'sharedOnDate',
    label: 'SHARED WITH ME ON',
    sortable: false,
  },
];

export default function DashboardBlk(props) {
  const { title, img, type = '' } = props;

  const [loader, setLoader] = useState(false);
  const [campaignInfo, setCampaignInfo] = useState({});
  const [filter, setFilter] = useState({
    sortField: 'id',
    ascendingOrder: false,
    recordsPerPage: 10,
    status: 'ACTIVE',
  });

  useEffect(() => {
    if (type === 'primaryDca') {
      fetchCampaignsAsPrimaryDcaApi(filter);
    } else {
      fetchCampaignsAsSecondaryDcaApi(filter);
    }
  }, []);

  const fetchCampaignsAsPrimaryDcaApi = (query = {}) => {
    setLoader(true);
    globalGetService(`dca/campaigns/primaryDca`, {
      ...query,
      sharingStatus: 'PENDING,ACCEPTED',
    }).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        setLoader(false);
        setCampaignInfo(response.data);
      }
    });
  };
  const fetchCampaignsAsSecondaryDcaApi = (query = {}) => {
    // Change sortField to createDate for Elasticsearch sorting
    query.sortField = 'createDate';
    setLoader(true);
    globalGetService(`dca/campaigns/secondaryDca`, query).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        setLoader(false);
        setCampaignInfo(response.data);
      }
    });
  };
  const handleSorting = (sortField) => {
    if (campaignInfo.totalRecords) {
      if (type === 'primaryDca') {
        fetchCampaignsAsPrimaryDcaApi({
          ...filter,
          page: campaignInfo.page,
          sortField,
          ascendingOrder: !filter.ascendingOrder,
        });
      } else {
        fetchCampaignsAsSecondaryDcaApi({
          ...filter,
          page: campaignInfo.page,
          sortField,
          ascendingOrder: !filter.ascendingOrder,
        });
      }
    }
    setFilter({
      ...filter,
      sortField,
    });
  };

  return (
    <div
      className={`dashboard-${
        type === 'primaryDca' ? 'sharedByCsps' : 'primaryDca'
      } block-wrapper`}
      data-testid="dashboardBlock"
      data-type={type === 'primaryDca' ? 'sharedByCsps' : 'primaryDca'}
    >
      <div className="title">
        <h3 className="heading2 text-center">
          <Box flexDirection="center" justifyContent="center">
            <Box margin={{ right: 'xs' }}>
              {type === 'primaryDca' ? (
                <FontAwesomeIcon icon={faBuildingCircleArrowRight} />
              ) : (
                <FontAwesomeIcon icon={faDiagramVenn} />
              )}
            </Box>
            <span>{title}</span>
          </Box>
        </h3>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <div style={{ height: 200, overflowY: 'auto' }}>
            <Table
              testId="dashboardTable"
              className="dashboard-campaigns-listing-table"
              disableHover
              headRows={
                type === 'primaryDca' ? byCspsHeadRows : byPrimaryDcaHeadRows
              }
              records={{ total: campaignInfo?.records?.length || 0 }}
              loading={loader}
              emptyState="no campaigns to view"
              tableData={campaignInfo?.records?.map((campaign, idx) =>
                type === 'primaryDca' ? (
                  <CampaignsByCspsListingRow
                    key={`CampaignsByCspsListingRow_${idx}`}
                    cspName={campaign.cspName}
                    usecase={campaign.usecase}
                    campaignUid={campaign.uid}
                    brandUid={campaign.brandUid}
                    sharedOnDate={campaign.sharedOnDate}
                  />
                ) : (
                  <CampaignsByPrimaryDcasListingRow
                    key={`CampaignsByPrimaryDcasListingRow_${idx}`}
                    primaryDcaName={campaign.primaryDcaName}
                    mnoName={campaign.mnoList?.[0]?.name}
                    campaignUid={campaign.uid}
                    brandUid={campaign.brandUid}
                    sharedOnDate={campaign.sharedOnDate}
                  />
                )
              )}
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          <DashboardCount
            type={type}
            count={type === 'primaryDca' ? '' : campaignInfo.totalRecords}
          />
        </Grid>
      </Grid>
    </div>
  );
}
