import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import { convertTimeWithTimezone } from '../../../../utils/time';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPen } from '@fortawesome/pro-regular-svg-icons';
import { UserData } from '../../types';
import { Roles } from '../../../../constants/enums';
import { Flex, BoxV2 as Box } from 'portal-commons';

interface Props {
  user: UserData;
  key: string;
  editable: boolean;
  onEditUser: (user: UserData) => void;
  onDeleteUser: (user: UserData) => void;
}

const UsersListingRow: React.FC<Props> = ({
  user,
  editable,
  onEditUser,
  onDeleteUser,
}) => {
  return (
    <TableRow data-testid="usersListingRow">
      <TableCell align="left" data-testid="usersListingRowFullName">
        {user.profile.fullName}
      </TableCell>
      <TableCell align="left" data-testid="usersListingRowEmail">
        {user.profile.email}
      </TableCell>
      <TableCell align="left" data-testid="usersListingRowUserId">
        {user.profile.dcaUid}
      </TableCell>
      <TableCell align="left" data-testid="usersListingRowRole">
        {user.profile.dcaUserRole
          ? user.profile.dcaUserRole[0]
          : Roles.Reviewer}
      </TableCell>
      <TableCell align="left" data-testid="usersListingRowCreatedDate">
        {convertTimeWithTimezone(user.created, 'MM/DD/YYYY')}
      </TableCell>
      <TableCell align="left" data-testid="usersListingRowLastLogin">
        {convertTimeWithTimezone(user.lastLogin, 'MM/DD/YYYY')}
      </TableCell>
      <TableCell
        align="right"
        data-testid="usersListingRowAction"
        style={{ width: 40 }}
      >
        {editable && (
          <Flex
            sx={{
              alignItem: 'center',
              justifyContent: 'flex-end',
              gap: 'xxs',
              marginRight: 'l',
            }}
          >
            <Box
              px="xxs"
              sx={{ cursor: 'pointer' }}
              onClick={() => onEditUser(user)}
              data-testid="usersListingRowEditButton"
            >
              <FontAwesomeIcon icon={faPen} />
            </Box>
            <Box
              px="xxs"
              sx={{ cursor: 'pointer' }}
              onClick={() => onDeleteUser(user)}
              data-testid="usersListingRowDeleteButton"
            >
              <FontAwesomeIcon icon={faTrash} />
            </Box>
          </Flex>
        )}
      </TableCell>
    </TableRow>
  );
};

export default UsersListingRow;
