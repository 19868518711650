import React, { useState } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { convertTimeWithTimezone } from '../../../../utils/time';
import { OsrPhoneAssociatedCampaign } from '../../types';

const useStyles = makeStyles(() => ({
  copy: {
    cursor: 'pointer',
    marginLeft: '5px',
    color: '#B3B7BB',
    fontSize: '10px',
    fontWeight: 400,
    lineHeight: 'normal',
    textDecorationLine: 'underline',
    '&.copied': {
      color: '#49535D',
      transition: '1s',
    },
  },
}));

export const formatUSPhoneNumber = (phoneNumber: string) => {
  if (phoneNumber && phoneNumber.length === 11 && phoneNumber.startsWith('1')) {
    const npa = phoneNumber.substring(1, 4);
    const nxx = phoneNumber.substring(4, 7);
    const xxxx = phoneNumber.substring(7);

    return `${npa}-${nxx}-${xxxx}`;
  }
  return phoneNumber;
};

interface Props {
  data: OsrPhoneAssociatedCampaign;
}

const NumbersProvisionedListingRow: React.FC<Props> = (props) => {
  const { data } = props;
  const classes = useStyles();
  const [phoneCopied, setPhoneCopied] = useState(false);

  const handleCopy = (number: string) => {
    if (navigator && navigator.clipboard) {
      navigator.clipboard.writeText(number);
      setPhoneCopied(true);
      setTimeout(() => {
        setPhoneCopied(false);
      }, 1000);
    }
  };

  return (
    <TableRow data-testid="numbersProvisionedListingRow">
      <TableCell align="left" data-testid="phoneNumber">
        <div>
          {formatUSPhoneNumber(data.phoneNumber)}
          <span
            className={`${classes.copy}${phoneCopied ? ' copied' : ''}`}
            onClick={() => handleCopy(formatUSPhoneNumber(data.phoneNumber))}
            data-testid="copyPhoneNumberButton"
          >
            {phoneCopied ? 'copied' : 'copy'}
          </span>
        </div>
      </TableCell>
      <TableCell align="left" data-testid="nnid">
        {data.nnid ? data.nnid : ''}
      </TableCell>
      <TableCell align="left" data-testid="provisionedOn">
        {data.startTimestamp
          ? convertTimeWithTimezone(data.startTimestamp)
          : ''}
      </TableCell>
    </TableRow>
  );
};

export default NumbersProvisionedListingRow;
