import React from 'react';
import { useSelector } from 'react-redux';
import { Backdrop, Container, makeStyles, Typography } from '@material-ui/core';
import Spinner from '../features/campaigns/components/Spinner';

const useStyles = makeStyles(() => ({
  backdrop: {
    background: 'rgba(14, 31, 43, 0.75)',
    zIndex: 1001,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 430,
    height: 226,
    background: '#FFFFFF',
  },
  typography: {
    marginTop: 40,
    color: '#49535D',
  },
}));

const LoadingModal = ({}) => {
  const message = useSelector((state) => state.shareReducer.loadingMessage);
  const classes = useStyles();

  return (
    <Backdrop
      classes={{ root: classes.backdrop }}
      open={!!message}
      data-testid="loaderModalBackdrop"
    >
      <Container
        classes={{ root: classes.container }}
        data-testid="loaderModalContainer"
      >
        <Spinner />
        <Typography
          classes={{ root: classes.typography }}
          variant="body1"
          align="center"
        >
          {message}
        </Typography>
      </Container>
    </Backdrop>
  );
};

export default LoadingModal;
